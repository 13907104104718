import { baseApi } from "@api/baseApi"
import { configureStore, type Action, type ThunkAction } from "@reduxjs/toolkit"
import { createReduxEnhancer } from "@sentry/react"
import authReducer from "./authSlice"
import commonsReducer from "./commonsSlice"
import onboardingReducer from "./onboardingSlice"

export const sentryReduxEnhancer = createReduxEnhancer()

const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    // [websocketApi.reducerPath]: websocketApi.reducer,

    commons: commonsReducer,
    auth: authReducer,
    onboarding: onboardingReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware /*websocketApi.middleware*/),

  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer)
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

if (window.Cypress) {
  window.store = store
}

export default store

import {
  leadbayApi,
  useGetLensesByLensIdLeadsNewCountsQuery,
  type LeadOrder,
  type NewableLead,
} from "@api/leadbayApi"
import { CENTERED_FLEX_COL } from "@constants/index"
import AnimatedNumber from "@crossfox/react-animated-number"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { useRecordUserInteractions } from "@hooks/useRecordUserInteractions"
import { CloudDownload, ThumbDown, ThumbUp } from "@mui/icons-material"
import { Box, Chip, LinearProgress, Tooltip, Typography } from "@mui/material"
import {
  DataGrid,
  GridToolbarProps,
  ToolbarPropsOverrides,
  useGridApiRef,
  type GridColDef,
  type GridPaginationModel,
  type GridRowParams,
  type GridRowSelectionModel,
  type GridSortModel,
} from "@mui/x-data-grid"
import {
  selectCommonsState,
  setPaginationModel,
  setSelectedIds,
  setSortModel,
  toggleNavDrawer,
  type PaginationModel,
} from "@redux/commonsSlice"
import { type TransformedEntry } from "@utils/generateWishListRows"
import { useAsyncEffect } from "ahooks"
import clsx from "clsx"
import { formatDate } from "date-fns"
import localforage from "localforage"
import { JSXElementConstructor, useCallback, useEffect, useState } from "react"
import { LbDatableCell } from "../LbDatatableCell/LbDatatableCell"

interface LbDataGridProps {
  rows: Array<TransformedEntry & { new: boolean }>
  rowCount?: number
  sortingMode?: "client" | "server"
  rowSelectionModel?: {
    rowSelectionModel: GridRowSelectionModel
    setRowSelectionModel: (newRowSelectionModel: GridRowSelectionModel) => void
  }
  toolbar?:
    | JSXElementConstructor<GridToolbarProps & ToolbarPropsOverrides>
    | null
    | undefined
  selectionEnabled?: boolean
  showLoader?: boolean
  showFullLoader?: boolean
  paginationModel?: PaginationModel
  hideFooter?: boolean
  columnsToHide?: string[]
  toolbarExportText?: string
  dataGridRef?: React.RefObject<HTMLDivElement>
}

export const LbWishlistGrid = ({
  paginationModel: paginationModelFromProps,
  rowSelectionModel,
  selectionEnabled,
  showLoader,
  toolbar,
  showFullLoader,
  sortingMode,
  rows,
  rowCount,
  hideFooter,
  columnsToHide,
  toolbarExportText,
  dataGridRef,
}: LbDataGridProps) => {
  const dispatch = useAppDispatch()

  const wishlistApiRef = useGridApiRef()

  const {
    paginationModel: _paginationModel,
    wishlistViewMode,
    currentLensId,
    selectedIds,
  } = useAppSelector(selectCommonsState)

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const { refetch: refetchCountData } = useGetLensesByLensIdLeadsNewCountsQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const paginationModel = paginationModelFromProps ?? _paginationModel

  const handleSetPaginationModel = useCallback(
    (params: GridPaginationModel) => {
      const { page, pageSize } = params

      dispatch(
        setPaginationModel({
          page,
          pageSize,
        }),
      )
    },
    [],
  )

  const handleRowClick = useCallback(
    async (dataGrid: GridRowParams) => {
      await handleRecordUserInteractions([
        {
          type: "LEAD_CLICKED",
          lead_id: String(dataGrid.id),
          lens_id: currentLensId,
        },
      ])

      const tempSelectedIds = [...selectedIds]

      if (dataGrid.row.new) {
        const index = selectedIds.indexOf(dataGrid.id as string)

        if (index === -1) {
          tempSelectedIds.push(dataGrid.id as string)
        }

        dispatch(setSelectedIds(tempSelectedIds))

        await refetchCountData()
      }

      dispatch(
        toggleNavDrawer({
          isOpen: true,
          partial: "LEAD_INFOS",
          data: dataGrid,
        }),
      )
    },
    [currentLensId, dispatch, handleRecordUserInteractions, selectedIds],
  )

  const handleSetSelectionModel = async (
    newRowSelectionModel: GridRowSelectionModel,
  ) => {
    await localforage.setItem(
      "wishlist_row_selection_model",
      newRowSelectionModel,
    )

    rowSelectionModel?.setRowSelectionModel(newRowSelectionModel)
  }

  const [gridSortModel, setGridSortModel] = useState<GridSortModel>([])

  const onSortChange = useCallback(
    (filterModel: GridSortModel) => {
      try {
        if (!filterModel || !filterModel.length) {
          dispatch(setSortModel(undefined))
          return
        }

        const field = filterModel?.[0]?.field?.toUpperCase()
        const sort = filterModel?.[0]?.sort?.toUpperCase()

        let generatedValue: LeadOrder | undefined

        if (field === "LIKED") {
          generatedValue = sort === "ASC" ? `DISLIKED:DESC` : `LIKED:DESC`
        } else {
          switch (field) {
            case "ID":
              generatedValue = `ID:${sort}` as LeadOrder
              break
            case "NAME":
              generatedValue = `NAME:${sort}` as LeadOrder
              break
            case "SCORE":
              generatedValue = `SCORE:${sort}` as LeadOrder
              break
            case "SECTOR":
              generatedValue = `SECTOR:${sort}` as LeadOrder
              break
            case "SIZE":
              generatedValue = `SIZE:${sort}` as LeadOrder
              break
            case "STATUS":
              generatedValue = `STATUS:${sort}` as LeadOrder
              break
            case "WEBSITE":
              generatedValue = `WEBSITE:${sort}` as LeadOrder
              break
            default:
              generatedValue = undefined
              break
          }
        }

        dispatch(setSortModel(generatedValue ? [generatedValue] : undefined))

        dispatch(leadbayApi.util.invalidateTags(["Leads"]))
      } catch (error) {
        setGridSortModel(filterModel)
        dispatch(setSortModel(undefined))
        console.error(error)
      } finally {
        setGridSortModel(filterModel)
      }
    },
    [wishlistViewMode],
  )

  useAsyncEffect(async () => {
    if (selectionEnabled) {
      const storedRowSelectionModel = await localforage.getItem<string>(
        "wishlist_row_selection_model",
      )

      rowSelectionModel?.setRowSelectionModel(
        storedRowSelectionModel
          ? (storedRowSelectionModel as unknown as GridRowSelectionModel)
          : [],
      )
    }
  }, [selectionEnabled])

  const columns: ReadonlyArray<
    GridColDef<
      TransformedEntry & {
        new: boolean
      }
    >
  > = [
    {
      field: "score",
      headerName: "Score",
      description: "Lead score based on the scoring parameters",
      type: "number",
      sortable: true,
      align: "center",
      headerAlign: "left",
      width: 80,
      renderCell: (params) => {
        const [localesSore, setLocalScore] = useState(0)

        useEffect(() => {
          let interval: NodeJS.Timeout | null = null

          if (showLoader) {
            let count = 0
            interval = setInterval(() => {
              setLocalScore((prevScore) => {
                const randomChange = Math.floor(Math.random() * 10) + 1
                const newScore =
                  count % 2 === 0
                    ? prevScore + randomChange
                    : prevScore - randomChange
                if (newScore < 0) return prevScore
                if (newScore > 100) return 100
                return newScore
              })
              count += 1
            }, 300)
          } else {
            setLocalScore(params.value)
          }

          return () => {
            if (interval) clearInterval(interval)
          }
        }, [params.value, showLoader])

        return (
          <>
            {params.value > 0 &&
              // @ts-expect-error Display the progress bar only if the lead is not WON or LOST
              params.row.state?.status !== "WON" &&
              // @ts-expect-error Display the progress bar only if the lead is not WON or LOST
              params.row.state?.status !== "LOST" && (
                <Box
                  sx={{
                    ...CENTERED_FLEX_COL,
                    width: "40px",
                    margin: "auto",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: "900",
                      mb: 0.2,
                      fontFamily: "BasementGrotesque",
                      color: "primary.main",
                    }}
                  >
                    <AnimatedNumber
                      value={localesSore}
                      duration={300}
                      suffix="&nbsp;"
                    />
                  </Typography>

                  <LinearProgress
                    color="success"
                    sx={{ width: "100%", height: 5 }}
                    variant={showLoader ? "indeterminate" : "determinate"}
                    value={showLoader ? 100 : localesSore}
                    className={clsx(showLoader && "animate-pulse")}
                  />
                </Box>
              )}
          </>
        )
      },
    },
    {
      field: "new",
      headerName: "New",
      description: "New leads are leads that have been added to the system.",
      sortable: true,
      width: 20,
      align: "center",
      sortingOrder: ["desc", "asc"],
      sortComparator: (v1, v2, param1, param2) => {
        const value1 = param1.value?.isNew === true ? 1 : 0
        const value2 = param2.value?.isNew === true ? 1 : 0
        return value2 - value1
      },
      renderCell: (params) => {
        return (
          params.value === true && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={[
                  (theme) => ({
                    background: theme.palette.success.main,
                    width: 7,
                    height: 7,
                    borderRadius: "100%",
                  }),
                ]}
              />
            </Box>
          )
        )
      },
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      description: "The name of the lead",
      minWidth: 250,
      renderCell: (params) => {
        const row = params.row as unknown as NewableLead

        const exportTooltipTitle =
          row?.origin?.type !== "EXPORTED"
            ? undefined
            : `Exported from whishlist on ${formatDate(row.origin.date, "y-m-d 'at' H:mm:ss")}`

        return (
          <Tooltip
            title={exportTooltipTitle}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <Box
              width="100%"
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  ...CENTERED_FLEX_COL,
                  height: "20px",
                }}
              >
                {row.logo && (
                  <img
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      marginRight: "8px",
                      borderRadius: "5px",
                    }}
                    src={row.logo}
                    alt={params.value}
                  />
                )}
              </Box>

              <Typography
                variant="subtitle2"
                overflow="hidden"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontFamily: "Hanken Grotesk",
                  pr: 1,
                }}
              >
                {params.value}
              </Typography>

              {row.origin?.type === "EXPORTED" && (
                <>
                  <Box sx={{ width: "8px" }} />

                  <CloudDownload
                    sx={{
                      fontSize: "1rem",
                    }}
                  />
                </>
              )}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      field: "status",
      headerName: "Status",
      description: "The status of the lead",
      sortable: true,
      width: 85,
      renderCell: (params) => {
        const status = params?.value?.status

        const renderChip = (
          label: string,
          color: "default" | "info" | "warning",
        ) => (
          <Box
            sx={{
              ...CENTERED_FLEX_COL,
              width: "100%",
            }}
          >
            <Chip
              label={<Box sx={{ px: 2 }}>{label}</Box>}
              sx={{
                fontWeight: 700,
                fontSize: "0.7rem",
                paddingLeft: "8px !important",
                paddingRight: "8px !important",
              }}
              color={color}
              size="small"
            />
          </Box>
        )

        if (wishlistViewMode === "monitor" && status) {
          return <>{renderChip(status, "default")}</>
        } else {
          return ""
        }
      },
    },
    {
      field: "status_prediction",
      headerName: "Change",
      description: "Predicted status evolution",
      width: 65,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        const statusPrediction = params?.value?.status
        const changed = params?.value?.changed

        return statusPrediction && changed ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              width: "100%",
            }}
          >
            <Typography>{statusPrediction === "WON" ? "⇧" : "⇩"}</Typography>
          </Box>
        ) : null
      },
    },
    {
      field: "sector",
      flex: 1,
      headerName: "Sector",
      description: "The sector of the lead",
      sortable: true,
      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="SECTOR"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "location",
      flex: 1,
      headerName: "Location",
      description: "The location of the lead",
      sortable: false,
      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="LOCATION"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "size",
      headerName: "Size",
      headerAlign: "center",
      description: "The size of the lead",
      align: "right",
      minWidth: 70,
      sortable: true,
      sortComparator: (v1, v2, param1, param2) => {
        return param2.value?.[1] - param1.value?.[1]
      },
      renderCell: ({ row, value }) => (
        <LbDatableCell
          type="SIZE"
          row={row as unknown as NewableLead}
          value={value}
        />
      ),
    },
    {
      field: "liked",
      headerName: "",
      align: "right",
      description: "Displays if the lead is a favorite",
      sortable: true,
      width: 50,
      sortComparator: (v1, v2, param1, param2) => {
        const likedValue1 =
          param1.value.liked === true
            ? 1
            : param1.value.disliked === true
              ? -1
              : 0
        const likedValue2 =
          param2.value.liked === true
            ? 1
            : param2.value.disliked === true
              ? -1
              : 0
        return likedValue2 - likedValue1
      },
      renderCell: (params) => {
        const row = params.row as unknown as NewableLead

        const icon = row?.liked ? (
          <Box>
            <ThumbUp color="primary" sx={{ fontSize: "0.9rem" }} />
          </Box>
        ) : row?.disliked ? (
          <Typography>
            <ThumbDown color="primary" sx={{ fontSize: "0.9rem" }} />
          </Typography>
        ) : (
          ""
        )

        return <Box sx={{ ...CENTERED_FLEX_COL }}>{icon}</Box>
      },
    },
  ]

  return (
    <DataGrid
      className={clsx(showFullLoader && "animate-pulse")}
      ref={dataGridRef}
      apiRef={wishlistApiRef}
      sortModel={gridSortModel}
      sortingMode={sortingMode ?? "server"}
      onSortModelChange={onSortChange}
      keepNonExistentRowsSelected
      onRowClick={handleRowClick}
      onPaginationModelChange={handleSetPaginationModel}
      paginationMode="server"
      rowCount={rowCount ?? rows.length}
      paginationModel={paginationModel}
      checkboxSelection={selectionEnabled}
      disableRowSelectionOnClick
      onRowSelectionModelChange={handleSetSelectionModel}
      getRowClassName={() => "lead-row"}
      rowSelectionModel={rowSelectionModel?.rowSelectionModel}
      initialState={{
        sorting: {
          sortModel: [{ field: "score", sort: "desc" }],
        },
      }}
      slots={{
        toolbar: toolbar,
      }}
      localeText={{
        toolbarExport: toolbarExportText
          ? toolbarExportText
          : selectionEnabled
            ? "Export selected"
            : "Enable selection",
      }}
      sx={{
        borderRadius: 0,
        border: 0,
        m: 0,
        backgroundColor: "#fff",
        ".MuiDataGrid-columnHeaderTitle": {
          color: "text.secondary",
        },
        "& .MuiDataGrid-cell[data-field=name]": {
          overflow: "visible",
        },
      }}
      pagination
      hideFooter={hideFooter}
      // @ts-expect-error clipboardPaste is an experimental feature
      experimentalFeatures={{ clipboardPaste: true }}
      unstable_ignoreValueFormatterDuringExport
      disableColumnFilter
      rowHeight={45}
      rows={rows}
      columns={columns.filter(
        (column) => !columnsToHide?.includes(column.field),
      )}
    />
  )
}

import { baseApi as api } from "./baseApi"
export const addTagTypes = [
  "Authentication",
  "OAuth",
  "Users",
  "Organizations",
  "Sectors",
  "Geographic Data",
  "Keywords",
  "Stripe",
  "Zapier",
  "Connectors",
  "Crawler",
  "CRM",
  "Export",
  "Imports",
  "Interactions",
  "Leads",
  "Lenses",
  "Timeline",
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAuthInvitesByInviteId: build.query<
        GetAuthInvitesByInviteIdApiResponse,
        GetAuthInvitesByInviteIdApiArg
      >({
        query: (queryArg) => ({ url: `/auth/invites/${queryArg.inviteId}` }),
        providesTags: ["Authentication"],
      }),
      postAuthRegister: build.mutation<
        PostAuthRegisterApiResponse,
        PostAuthRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/register`,
          method: "POST",
          body: queryArg.userRegistration,
          params: { code: queryArg.code },
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthCheckEmail: build.mutation<
        PostAuthCheckEmailApiResponse,
        PostAuthCheckEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/check_email`,
          method: "POST",
          body: queryArg.checkEmail,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthResendEmail: build.mutation<
        PostAuthResendEmailApiResponse,
        PostAuthResendEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/resend_email`,
          method: "POST",
          params: { code: queryArg.code },
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthVerifyEmail: build.mutation<
        PostAuthVerifyEmailApiResponse,
        PostAuthVerifyEmailApiArg
      >({
        query: () => ({ url: `/auth/verify_email`, method: "POST" }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthVerifyEmailCode: build.mutation<
        PostAuthVerifyEmailCodeApiResponse,
        PostAuthVerifyEmailCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/verify_email_code`,
          method: "POST",
          body: queryArg.emailVerifyCode,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthLogin: build.mutation<
        PostAuthLoginApiResponse,
        PostAuthLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/login`,
          method: "POST",
          body: queryArg.userLogin,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthLogout: build.mutation<
        PostAuthLogoutApiResponse,
        PostAuthLogoutApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/logout`,
          method: "POST",
          body: queryArg.authLogoutPostRequest,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthInvite: build.mutation<
        PostAuthInviteApiResponse,
        PostAuthInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/invite`,
          method: "POST",
          body: queryArg.authInvitePostRequest,
        }),
        invalidatesTags: ["Authentication"],
      }),
      getAuthInvitesStatus: build.query<
        GetAuthInvitesStatusApiResponse,
        GetAuthInvitesStatusApiArg
      >({
        query: () => ({ url: `/auth/invites_status` }),
        providesTags: ["Authentication"],
      }),
      postAuthChangePassword: build.mutation<
        PostAuthChangePasswordApiResponse,
        PostAuthChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/change_password`,
          method: "POST",
          body: queryArg.passwordChange,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthPasswordResetStart: build.mutation<
        PostAuthPasswordResetStartApiResponse,
        PostAuthPasswordResetStartApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password_reset/start`,
          method: "POST",
          body: queryArg.authPasswordResetStartPostRequest,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthPasswordResetChangePassword: build.mutation<
        PostAuthPasswordResetChangePasswordApiResponse,
        PostAuthPasswordResetChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password_reset/change_password`,
          method: "POST",
          body: queryArg.passwordReset,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthGoogleLogin: build.mutation<
        PostAuthGoogleLoginApiResponse,
        PostAuthGoogleLoginApiArg
      >({
        query: () => ({ url: `/auth/google/login`, method: "POST" }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthGoogleRegister: build.mutation<
        PostAuthGoogleRegisterApiResponse,
        PostAuthGoogleRegisterApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/google/register`,
          method: "POST",
          body: queryArg.googleUserRegistration,
        }),
        invalidatesTags: ["Authentication"],
      }),
      postAuthGoogleLink: build.mutation<
        PostAuthGoogleLinkApiResponse,
        PostAuthGoogleLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/google/link`,
          method: "POST",
          body: queryArg.googleLink,
        }),
        invalidatesTags: ["Authentication"],
      }),
      getAuthSearchOrgs: build.query<
        GetAuthSearchOrgsApiResponse,
        GetAuthSearchOrgsApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/search_orgs`,
          params: { q: queryArg.q },
        }),
        providesTags: ["Authentication"],
      }),
      getOauthApplicationsByAppId: build.query<
        GetOauthApplicationsByAppIdApiResponse,
        GetOauthApplicationsByAppIdApiArg
      >({
        query: (queryArg) => ({ url: `/oauth/applications/${queryArg.appId}` }),
        providesTags: ["OAuth"],
      }),
      postOauthGrants: build.mutation<
        PostOauthGrantsApiResponse,
        PostOauthGrantsApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/grants`,
          method: "POST",
          body: queryArg.oAuthCreateGrant,
        }),
        invalidatesTags: ["OAuth"],
      }),
      postOauthToken: build.mutation<
        PostOauthTokenApiResponse,
        PostOauthTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/oauth/token`,
          method: "POST",
          params: {
            grant_type: queryArg.grantType,
            code: queryArg.code,
            client_id: queryArg.clientId,
            client_secret: queryArg.clientSecret,
            redirect_uri: queryArg.redirectUri,
          },
        }),
        invalidatesTags: ["OAuth"],
      }),
      deleteUsersMe: build.mutation<
        DeleteUsersMeApiResponse,
        DeleteUsersMeApiArg
      >({
        query: () => ({ url: `/users/me`, method: "DELETE" }),
        invalidatesTags: ["Users"],
      }),
      getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
        query: () => ({ url: `/users/me` }),
        providesTags: ["Users"],
      }),
      deleteOrganizationsByOrgId: build.mutation<
        DeleteOrganizationsByOrgIdApiResponse,
        DeleteOrganizationsByOrgIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Organizations"],
      }),
      getOrganizationsByOrgId: build.query<
        GetOrganizationsByOrgIdApiResponse,
        GetOrganizationsByOrgIdApiArg
      >({
        query: (queryArg) => ({ url: `/organizations/${queryArg.orgId}` }),
        providesTags: ["Organizations"],
      }),
      postOrganizationsByOrgId: build.mutation<
        PostOrganizationsByOrgIdApiResponse,
        PostOrganizationsByOrgIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}`,
          method: "POST",
          body: queryArg.orgUpdate,
        }),
        invalidatesTags: ["Organizations"],
      }),
      getOrganizationsByOrgIdInvites: build.query<
        GetOrganizationsByOrgIdInvitesApiResponse,
        GetOrganizationsByOrgIdInvitesApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/invites`,
        }),
        providesTags: ["Organizations"],
      }),
      postOrganizationsByOrgIdInvites: build.mutation<
        PostOrganizationsByOrgIdInvitesApiResponse,
        PostOrganizationsByOrgIdInvitesApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/invites`,
          method: "POST",
          body: queryArg.inviteCreation,
        }),
        invalidatesTags: ["Organizations"],
      }),
      deleteOrganizationsByOrgIdInvitesAndInviteId: build.mutation<
        DeleteOrganizationsByOrgIdInvitesAndInviteIdApiResponse,
        DeleteOrganizationsByOrgIdInvitesAndInviteIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/invites/${queryArg.inviteId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Organizations"],
      }),
      postOrganizationsByOrgIdInvitesAndInviteId: build.mutation<
        PostOrganizationsByOrgIdInvitesAndInviteIdApiResponse,
        PostOrganizationsByOrgIdInvitesAndInviteIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/invites/${queryArg.inviteId}`,
          method: "POST",
          body: queryArg.organizationsOrgIdInvitesInviteIdPostRequest,
        }),
        invalidatesTags: ["Organizations"],
      }),
      getOrganizationsByOrgIdUsers: build.query<
        GetOrganizationsByOrgIdUsersApiResponse,
        GetOrganizationsByOrgIdUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/users`,
        }),
        providesTags: ["Organizations"],
      }),
      deleteOrganizationsByOrgIdUsersAndUserId: build.mutation<
        DeleteOrganizationsByOrgIdUsersAndUserIdApiResponse,
        DeleteOrganizationsByOrgIdUsersAndUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/users/${queryArg.userId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Organizations"],
      }),
      postOrganizationsByOrgIdUsersAndUserId: build.mutation<
        PostOrganizationsByOrgIdUsersAndUserIdApiResponse,
        PostOrganizationsByOrgIdUsersAndUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/users/${queryArg.userId}`,
          method: "POST",
          body: queryArg.organizationsOrgIdInvitesInviteIdPostRequest,
        }),
        invalidatesTags: ["Organizations"],
      }),
      getSectorsAll: build.query<GetSectorsAllApiResponse, GetSectorsAllApiArg>(
        {
          query: () => ({ url: `/sectors/all` }),
          providesTags: ["Sectors"],
        }
      ),
      getGeoSearch: build.query<GetGeoSearchApiResponse, GetGeoSearchApiArg>({
        query: (queryArg) => ({
          url: `/geo/search`,
          params: { q: queryArg.q },
        }),
        providesTags: ["Geographic Data"],
      }),
      getKeywordsSearch: build.query<
        GetKeywordsSearchApiResponse,
        GetKeywordsSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/keywords/search`,
          params: { q: queryArg.q },
        }),
        providesTags: ["Keywords"],
      }),
      getStripeCheckout: build.query<
        GetStripeCheckoutApiResponse,
        GetStripeCheckoutApiArg
      >({
        query: () => ({ url: `/stripe/checkout` }),
        providesTags: ["Stripe"],
      }),
      getStripePortal: build.query<
        GetStripePortalApiResponse,
        GetStripePortalApiArg
      >({
        query: () => ({ url: `/stripe/portal` }),
        providesTags: ["Stripe"],
      }),
      getZapierTest: build.query<GetZapierTestApiResponse, GetZapierTestApiArg>(
        {
          query: () => ({ url: `/zapier/test` }),
          providesTags: ["Zapier"],
        }
      ),
      postZapierWebhooks: build.mutation<
        PostZapierWebhooksApiResponse,
        PostZapierWebhooksApiArg
      >({
        query: (queryArg) => ({
          url: `/zapier/webhooks`,
          method: "POST",
          body: queryArg.createWebhook,
        }),
        invalidatesTags: ["Zapier"],
      }),
      deleteZapierWebhooksByWebhookId: build.mutation<
        DeleteZapierWebhooksByWebhookIdApiResponse,
        DeleteZapierWebhooksByWebhookIdApiArg
      >({
        query: (queryArg) => ({
          url: `/zapier/webhooks/${queryArg.webhookId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Zapier"],
      }),
      getZapierWebhooksSamplesByTrigger: build.query<
        GetZapierWebhooksSamplesByTriggerApiResponse,
        GetZapierWebhooksSamplesByTriggerApiArg
      >({
        query: (queryArg) => ({
          url: `/zapier/webhooks/samples/${queryArg.trigger}`,
        }),
        providesTags: ["Zapier"],
      }),
      getZapierActionsCreateLead: build.query<
        GetZapierActionsCreateLeadApiResponse,
        GetZapierActionsCreateLeadApiArg
      >({
        query: (queryArg) => ({
          url: `/zapier/actions/create_lead`,
          body: queryArg.createLead,
        }),
        providesTags: ["Zapier"],
      }),
      getConnectors: build.query<GetConnectorsApiResponse, GetConnectorsApiArg>(
        {
          query: () => ({ url: `/connectors` }),
          providesTags: ["Connectors"],
        }
      ),
      getAuthWs: build.query<GetAuthWsApiResponse, GetAuthWsApiArg>({
        query: (queryArg) => ({ url: `/auth/ws`, params: { v: queryArg.v } }),
        providesTags: ["Authentication"],
      }),
      deleteConnectorsByConnectorId: build.mutation<
        DeleteConnectorsByConnectorIdApiResponse,
        DeleteConnectorsByConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/connectors/${queryArg.connectorId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Connectors"],
      }),
      postConnectorsByConnectorId: build.mutation<
        PostConnectorsByConnectorIdApiResponse,
        PostConnectorsByConnectorIdApiArg
      >({
        query: (queryArg) => ({
          url: `/connectors/${queryArg.connectorId}`,
          method: "POST",
          body: queryArg.connectorUpdate,
        }),
        invalidatesTags: ["Connectors"],
      }),
      getConnectorsByConnectorIdMappings: build.query<
        GetConnectorsByConnectorIdMappingsApiResponse,
        GetConnectorsByConnectorIdMappingsApiArg
      >({
        query: (queryArg) => ({
          url: `/connectors/${queryArg.connectorId}/mappings`,
        }),
        providesTags: ["Connectors"],
      }),
      postConnectorsByConnectorIdStatusMappings: build.mutation<
        PostConnectorsByConnectorIdStatusMappingsApiResponse,
        PostConnectorsByConnectorIdStatusMappingsApiArg
      >({
        query: (queryArg) => ({
          url: `/connectors/${queryArg.connectorId}/status_mappings`,
          method: "POST",
          body: queryArg.statusMappings,
        }),
        invalidatesTags: ["Connectors"],
      }),
      postCrawlerHooksCrawl: build.mutation<
        PostCrawlerHooksCrawlApiResponse,
        PostCrawlerHooksCrawlApiArg
      >({
        query: (queryArg) => ({
          url: `/crawler/hooks/crawl`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Crawler"],
      }),
      getCrmCustomFields: build.query<
        GetCrmCustomFieldsApiResponse,
        GetCrmCustomFieldsApiArg
      >({
        query: () => ({ url: `/crm/custom_fields` }),
        providesTags: ["CRM"],
      }),
      postCrmCustomFields: build.mutation<
        PostCrmCustomFieldsApiResponse,
        PostCrmCustomFieldsApiArg
      >({
        query: (queryArg) => ({
          url: `/crm/custom_fields`,
          method: "POST",
          body: queryArg.createCustomField,
        }),
        invalidatesTags: ["CRM"],
      }),
      deleteCrmCustomFieldsByFieldId: build.mutation<
        DeleteCrmCustomFieldsByFieldIdApiResponse,
        DeleteCrmCustomFieldsByFieldIdApiArg
      >({
        query: (queryArg) => ({
          url: `/crm/custom_fields/${queryArg.fieldId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["CRM"],
      }),
      postCrmCustomFieldsByFieldId: build.mutation<
        PostCrmCustomFieldsByFieldIdApiResponse,
        PostCrmCustomFieldsByFieldIdApiArg
      >({
        query: (queryArg) => ({
          url: `/crm/custom_fields/${queryArg.fieldId}`,
          method: "POST",
          body: queryArg.updateCustomField,
        }),
        invalidatesTags: ["CRM"],
      }),
      getExportTargets: build.query<
        GetExportTargetsApiResponse,
        GetExportTargetsApiArg
      >({
        query: () => ({ url: `/export/targets` }),
        providesTags: ["Export"],
      }),
      postExportTargetsByTargetIdSetDefault: build.mutation<
        PostExportTargetsByTargetIdSetDefaultApiResponse,
        PostExportTargetsByTargetIdSetDefaultApiArg
      >({
        query: (queryArg) => ({
          url: `/export/targets/${queryArg.targetId}/set_default`,
          method: "POST",
        }),
        invalidatesTags: ["Export"],
      }),
      getImports: build.query<GetImportsApiResponse, GetImportsApiArg>({
        query: () => ({ url: `/imports` }),
        providesTags: ["Imports"],
      }),
      postImports: build.mutation<PostImportsApiResponse, PostImportsApiArg>({
        query: (queryArg) => ({
          url: `/imports`,
          method: "POST",
          body: queryArg.body,
          params: { file_name: queryArg.fileName },
        }),
        invalidatesTags: ["Imports"],
      }),
      getImportsByImportId: build.query<
        GetImportsByImportIdApiResponse,
        GetImportsByImportIdApiArg
      >({
        query: (queryArg) => ({ url: `/imports/${queryArg.importId}` }),
        providesTags: ["Imports"],
      }),
      postImportsByImportIdUpdateMappings: build.mutation<
        PostImportsByImportIdUpdateMappingsApiResponse,
        PostImportsByImportIdUpdateMappingsApiArg
      >({
        query: (queryArg) => ({
          url: `/imports/${queryArg.importId}/update_mappings`,
          method: "POST",
          body: queryArg.mappings1,
        }),
        invalidatesTags: ["Imports"],
      }),
      postImportsByImportIdUpdateFieldMappings: build.mutation<
        PostImportsByImportIdUpdateFieldMappingsApiResponse,
        PostImportsByImportIdUpdateFieldMappingsApiArg
      >({
        query: (queryArg) => ({
          url: `/imports/${queryArg.importId}/update_field_mappings`,
          method: "POST",
          body: queryArg.fieldMappings,
        }),
        invalidatesTags: ["Imports"],
      }),
      postInteractions: build.mutation<
        PostInteractionsApiResponse,
        PostInteractionsApiArg
      >({
        query: (queryArg) => ({
          url: `/interactions`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Interactions"],
      }),
      getLeadsLikes: build.query<GetLeadsLikesApiResponse, GetLeadsLikesApiArg>(
        {
          query: (queryArg) => ({
            url: `/leads/likes`,
            params: { count: queryArg.count, page: queryArg.page },
          }),
          providesTags: ["Leads"],
        }
      ),
      getLeadsDislikes: build.query<
        GetLeadsDislikesApiResponse,
        GetLeadsDislikesApiArg
      >({
        query: (queryArg) => ({
          url: `/leads/dislikes`,
          params: { count: queryArg.count, page: queryArg.page },
        }),
        providesTags: ["Leads"],
      }),
      postLeadsByLeadIdLike: build.mutation<
        PostLeadsByLeadIdLikeApiResponse,
        PostLeadsByLeadIdLikeApiArg
      >({
        query: (queryArg) => ({
          url: `/leads/${queryArg.leadId}/like`,
          method: "POST",
        }),
      }),
      postLeadsByLeadIdDislike: build.mutation<
        PostLeadsByLeadIdDislikeApiResponse,
        PostLeadsByLeadIdDislikeApiArg
      >({
        query: (queryArg) => ({
          url: `/leads/${queryArg.leadId}/dislike`,
          method: "POST",
        }),
      }),
      postLeadsByLeadIdClearLike: build.mutation<
        PostLeadsByLeadIdClearLikeApiResponse,
        PostLeadsByLeadIdClearLikeApiArg
      >({
        query: (queryArg) => ({
          url: `/leads/${queryArg.leadId}/clear_like`,
          method: "POST",
        }),
      }),
      postLeadsByLeadIdSetStatus: build.mutation<
        PostLeadsByLeadIdSetStatusApiResponse,
        PostLeadsByLeadIdSetStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/leads/${queryArg.leadId}/set_status`,
          method: "POST",
          body: queryArg.setStatusRequest,
        }),
        invalidatesTags: ["Leads"],
      }),
      getLenses: build.query<GetLensesApiResponse, GetLensesApiArg>({
        query: () => ({ url: `/lenses` }),
        providesTags: ["Lenses"],
      }),
      postLenses: build.mutation<PostLensesApiResponse, PostLensesApiArg>({
        query: (queryArg) => ({
          url: `/lenses`,
          method: "POST",
          body: queryArg.createLens,
        }),
        invalidatesTags: ["Lenses"],
      }),
      deleteLensesByLensId: build.mutation<
        DeleteLensesByLensIdApiResponse,
        DeleteLensesByLensIdApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Lenses"],
      }),
      getLensesByLensId: build.query<
        GetLensesByLensIdApiResponse,
        GetLensesByLensIdApiArg
      >({
        query: (queryArg) => ({ url: `/lenses/${queryArg.lensId}` }),
        providesTags: ["Lenses"],
      }),
      postLensesByLensId: build.mutation<
        PostLensesByLensIdApiResponse,
        PostLensesByLensIdApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}`,
          method: "POST",
          body: queryArg.updateLens,
        }),
        invalidatesTags: ["Lenses"],
      }),
      postLensesByLensIdDraft: build.mutation<
        PostLensesByLensIdDraftApiResponse,
        PostLensesByLensIdDraftApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/draft`,
          method: "POST",
        }),
        invalidatesTags: ["Lenses"],
      }),
      postLensesByLensIdPromote: build.mutation<
        PostLensesByLensIdPromoteApiResponse,
        PostLensesByLensIdPromoteApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/promote`,
          method: "POST",
        }),
        invalidatesTags: ["Lenses"],
      }),
      postLensesByLensIdDemote: build.mutation<
        PostLensesByLensIdDemoteApiResponse,
        PostLensesByLensIdDemoteApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/demote`,
          method: "POST",
        }),
        invalidatesTags: ["Lenses"],
      }),
      getLensesByLensIdFilter: build.query<
        GetLensesByLensIdFilterApiResponse,
        GetLensesByLensIdFilterApiArg
      >({
        query: (queryArg) => ({ url: `/lenses/${queryArg.lensId}/filter` }),
        providesTags: ["Lenses"],
      }),
      postLensesByLensIdFilter: build.mutation<
        PostLensesByLensIdFilterApiResponse,
        PostLensesByLensIdFilterApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/filter`,
          method: "POST",
          body: queryArg.filter,
        }),
        invalidatesTags: ["Lenses"],
      }),
      getLensesByLensIdScoring: build.query<
        GetLensesByLensIdScoringApiResponse,
        GetLensesByLensIdScoringApiArg
      >({
        query: (queryArg) => ({ url: `/lenses/${queryArg.lensId}/scoring` }),
        providesTags: ["Lenses"],
      }),
      postLensesByLensIdScoring: build.mutation<
        PostLensesByLensIdScoringApiResponse,
        PostLensesByLensIdScoringApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/scoring`,
          method: "POST",
          body: queryArg.scoringConfig,
        }),
        invalidatesTags: ["Lenses"],
      }),
      getLensesByLensIdLeads: build.query<
        GetLensesByLensIdLeadsApiResponse,
        GetLensesByLensIdLeadsApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads`,
          params: {
            wished: queryArg.wished,
            status: queryArg.status,
            owner: queryArg.owner,
            q: queryArg.q,
            count: queryArg.count,
            page: queryArg.page,
            order: queryArg.order,
          },
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsNewCounts: build.query<
        GetLensesByLensIdLeadsNewCountsApiResponse,
        GetLensesByLensIdLeadsNewCountsApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/new_counts`,
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsOnboarding: build.query<
        GetLensesByLensIdLeadsOnboardingApiResponse,
        GetLensesByLensIdLeadsOnboardingApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/onboarding`,
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsTrash: build.query<
        GetLensesByLensIdLeadsTrashApiResponse,
        GetLensesByLensIdLeadsTrashApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/trash`,
          params: {
            q: queryArg.q,
            count: queryArg.count,
            page: queryArg.page,
            order: queryArg.order,
          },
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsWishlist: build.query<
        GetLensesByLensIdLeadsWishlistApiResponse,
        GetLensesByLensIdLeadsWishlistApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/wishlist`,
          params: {
            q: queryArg.q,
            count: queryArg.count,
            page: queryArg.page,
            order: queryArg.order,
          },
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsAndLeadId: build.query<
        GetLensesByLensIdLeadsAndLeadIdApiResponse,
        GetLensesByLensIdLeadsAndLeadIdApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/${queryArg.leadId}`,
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsAndLeadIdPredictStatus: build.query<
        GetLensesByLensIdLeadsAndLeadIdPredictStatusApiResponse,
        GetLensesByLensIdLeadsAndLeadIdPredictStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/${queryArg.leadId}/predict_status`,
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsAndLeadIdSimilarPast: build.query<
        GetLensesByLensIdLeadsAndLeadIdSimilarPastApiResponse,
        GetLensesByLensIdLeadsAndLeadIdSimilarPastApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/${queryArg.leadId}/similar_past`,
        }),
        providesTags: ["Leads"],
      }),
      getLensesByLensIdLeadsAndLeadIdSimilarWished: build.query<
        GetLensesByLensIdLeadsAndLeadIdSimilarWishedApiResponse,
        GetLensesByLensIdLeadsAndLeadIdSimilarWishedApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/${queryArg.leadId}/similar_wished`,
        }),
        providesTags: ["Leads"],
      }),
      postLensesByLensIdLeadsAndLeadIdUntrash: build.mutation<
        PostLensesByLensIdLeadsAndLeadIdUntrashApiResponse,
        PostLensesByLensIdLeadsAndLeadIdUntrashApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/leads/${queryArg.leadId}/untrash`,
          method: "POST",
        }),
        invalidatesTags: ["Leads"],
      }),
      postLensesByLensIdExportMonitor: build.mutation<
        PostLensesByLensIdExportMonitorApiResponse,
        PostLensesByLensIdExportMonitorApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/export/monitor`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Export"],
      }),
      postLensesByLensIdExportMonitorAll: build.mutation<
        PostLensesByLensIdExportMonitorAllApiResponse,
        PostLensesByLensIdExportMonitorAllApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/export/monitor/all`,
          method: "POST",
        }),
        invalidatesTags: ["Export"],
      }),
      postLensesByLensIdExportWishlist: build.mutation<
        PostLensesByLensIdExportWishlistApiResponse,
        PostLensesByLensIdExportWishlistApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/export/wishlist`,
          method: "POST",
          body: queryArg.doExportRequest,
        }),
        invalidatesTags: ["Export"],
      }),
      postLensesByLensIdExportWishlistAll: build.mutation<
        PostLensesByLensIdExportWishlistAllApiResponse,
        PostLensesByLensIdExportWishlistAllApiArg
      >({
        query: (queryArg) => ({
          url: `/lenses/${queryArg.lensId}/export/wishlist/all`,
          method: "POST",
          body: queryArg.doExportAllRequest,
        }),
        invalidatesTags: ["Export"],
      }),
      getOrganizationsByOrgIdFeatureFlags: build.query<
        GetOrganizationsByOrgIdFeatureFlagsApiResponse,
        GetOrganizationsByOrgIdFeatureFlagsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.orgId}/feature_flags`,
        }),
        providesTags: ["Organizations"],
      }),
      getTimelineBlocks: build.query<
        GetTimelineBlocksApiResponse,
        GetTimelineBlocksApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/blocks`,
          params: { count: queryArg.count, page: queryArg.page },
        }),
        providesTags: ["Timeline"],
      }),
      postTimelineBlocksByBlockIdExportLeads: build.mutation<
        PostTimelineBlocksByBlockIdExportLeadsApiResponse,
        PostTimelineBlocksByBlockIdExportLeadsApiArg
      >({
        query: (queryArg) => ({
          url: `/timeline/blocks/${queryArg.blockId}/export_leads`,
          method: "POST",
          body: queryArg.doExportRequest,
        }),
        invalidatesTags: ["Export"],
      }),
      getTimelineNewCount: build.query<
        GetTimelineNewCountApiResponse,
        GetTimelineNewCountApiArg
      >({
        query: () => ({ url: `/timeline/new_count` }),
        providesTags: ["Timeline"],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as leadbayApi }
export type GetAuthInvitesByInviteIdApiResponse =
  /** status 200 OK */ AuthInvitesInviteIdGet200Response
export type GetAuthInvitesByInviteIdApiArg = {
  inviteId: string
}
export type PostAuthRegisterApiResponse =
  /** status 200 OK */ AuthRegisterPost200Response
export type PostAuthRegisterApiArg = {
  /** Send a code by email instead of a link */
  code?: boolean
  userRegistration: UserRegistration
}
export type PostAuthCheckEmailApiResponse = /** status 204 OK */ void
export type PostAuthCheckEmailApiArg = {
  checkEmail: CheckEmail
}
export type PostAuthResendEmailApiResponse = /** status 204 OK */ void
export type PostAuthResendEmailApiArg = {
  /** Send a code by email instead of a link */
  code?: boolean
}
export type PostAuthVerifyEmailApiResponse = /** status 204 OK */ void
export type PostAuthVerifyEmailApiArg = void
export type PostAuthVerifyEmailCodeApiResponse = /** status 204 OK */ void
export type PostAuthVerifyEmailCodeApiArg = {
  emailVerifyCode: EmailVerifyCode
}
export type PostAuthLoginApiResponse =
  /** status 200 Successful login */ AuthLoginPost200Response
export type PostAuthLoginApiArg = {
  userLogin: UserLogin
}
export type PostAuthLogoutApiResponse = /** status 204 OK */ void
export type PostAuthLogoutApiArg = {
  authLogoutPostRequest: AuthLogoutPostRequest
}
export type PostAuthInviteApiResponse = /** status 204 OK */ void
export type PostAuthInviteApiArg = {
  authInvitePostRequest: AuthInvitePostRequest
}
export type GetAuthInvitesStatusApiResponse = /** status 200 OK */ InvitesStatus
export type GetAuthInvitesStatusApiArg = void
export type PostAuthChangePasswordApiResponse = /** status 204 OK */ void
export type PostAuthChangePasswordApiArg = {
  passwordChange: PasswordChange
}
export type PostAuthPasswordResetStartApiResponse = /** status 204 OK */ void
export type PostAuthPasswordResetStartApiArg = {
  authPasswordResetStartPostRequest: AuthPasswordResetStartPostRequest
}
export type PostAuthPasswordResetChangePasswordApiResponse =
  /** status 204 OK */ void
export type PostAuthPasswordResetChangePasswordApiArg = {
  passwordReset: PasswordReset
}
export type PostAuthGoogleLoginApiResponse =
  /** status 200 OK */ GoogleLoginResponse
export type PostAuthGoogleLoginApiArg = void
export type PostAuthGoogleRegisterApiResponse =
  /** status 200 OK */ AuthRegisterPost200Response
export type PostAuthGoogleRegisterApiArg = {
  googleUserRegistration: GoogleUserRegistration
}
export type PostAuthGoogleLinkApiResponse = /** status 204 OK */ void
export type PostAuthGoogleLinkApiArg = {
  googleLink: GoogleLink
}
export type GetAuthSearchOrgsApiResponse = /** status 200 OK */ LeadSimplified[]
export type GetAuthSearchOrgsApiArg = {
  q: string
}
export type GetOauthApplicationsByAppIdApiResponse =
  /** status 200 OK */ OAuthApplication
export type GetOauthApplicationsByAppIdApiArg = {
  appId: string
}
export type PostOauthGrantsApiResponse =
  /** status 200 OK */ OAuthCreateGrantResponse
export type PostOauthGrantsApiArg = {
  oAuthCreateGrant: OAuthCreateGrant
}
export type PostOauthTokenApiResponse =
  /** status 200 OK */ OAuthAccessTokenResponse
export type PostOauthTokenApiArg = {
  grantType: string
  code: string
  clientId: string
  clientSecret: string
  redirectUri: string
}
export type DeleteUsersMeApiResponse = /** status 204 OK */ void
export type DeleteUsersMeApiArg = void
export type GetUsersMeApiResponse = /** status 200 OK */ UserWithOrg
export type GetUsersMeApiArg = void
export type DeleteOrganizationsByOrgIdApiResponse = /** status 204 OK */ void
export type DeleteOrganizationsByOrgIdApiArg = {
  orgId: string
}
export type GetOrganizationsByOrgIdApiResponse =
  /** status 200 OK */ Organization
export type GetOrganizationsByOrgIdApiArg = {
  orgId: string
}
export type PostOrganizationsByOrgIdApiResponse = /** status 204 OK */ void
export type PostOrganizationsByOrgIdApiArg = {
  orgId: string
  orgUpdate: OrgUpdate
}
export type GetOrganizationsByOrgIdInvitesApiResponse =
  /** status 200 OK */ Invite[]
export type GetOrganizationsByOrgIdInvitesApiArg = {
  orgId: string
}
export type PostOrganizationsByOrgIdInvitesApiResponse =
  /** status 200 OK */ string
export type PostOrganizationsByOrgIdInvitesApiArg = {
  orgId: string
  inviteCreation: InviteCreation
}
export type DeleteOrganizationsByOrgIdInvitesAndInviteIdApiResponse =
  /** status 204 OK */ void
export type DeleteOrganizationsByOrgIdInvitesAndInviteIdApiArg = {
  orgId: string
  inviteId: string
}
export type PostOrganizationsByOrgIdInvitesAndInviteIdApiResponse =
  /** status 204 OK */ void
export type PostOrganizationsByOrgIdInvitesAndInviteIdApiArg = {
  orgId: string
  inviteId: string
  organizationsOrgIdInvitesInviteIdPostRequest: OrganizationsOrgIdInvitesInviteIdPostRequest
}
export type GetOrganizationsByOrgIdUsersApiResponse =
  /** status 200 OK */ User[]
export type GetOrganizationsByOrgIdUsersApiArg = {
  orgId: string
}
export type DeleteOrganizationsByOrgIdUsersAndUserIdApiResponse =
  /** status 204 OK */ void
export type DeleteOrganizationsByOrgIdUsersAndUserIdApiArg = {
  orgId: string
  userId: string
}
export type PostOrganizationsByOrgIdUsersAndUserIdApiResponse =
  /** status 204 OK */ void
export type PostOrganizationsByOrgIdUsersAndUserIdApiArg = {
  orgId: string
  userId: string
  organizationsOrgIdInvitesInviteIdPostRequest: OrganizationsOrgIdInvitesInviteIdPostRequest
}
export type GetSectorsAllApiResponse = /** status 200 OK */ string[]
export type GetSectorsAllApiArg = void
export type GetGeoSearchApiResponse = /** status 200 OK */ LeadLocation[]
export type GetGeoSearchApiArg = {
  q: string
}
export type GetKeywordsSearchApiResponse = /** status 200 OK */ string[]
export type GetKeywordsSearchApiArg = {
  q: string
}
export type GetStripeCheckoutApiResponse =
  /** status 200 OK */ StripeCheckoutGet200Response
export type GetStripeCheckoutApiArg = void
export type GetStripePortalApiResponse =
  /** status 200 OK */ StripeCheckoutGet200Response
export type GetStripePortalApiArg = void
export type GetZapierTestApiResponse = /** status 200 OK */ Organization
export type GetZapierTestApiArg = void
export type PostZapierWebhooksApiResponse = /** status 200 OK */ Webhook
export type PostZapierWebhooksApiArg = {
  createWebhook: CreateWebhook
}
export type DeleteZapierWebhooksByWebhookIdApiResponse =
  /** status 204 OK */ void
export type DeleteZapierWebhooksByWebhookIdApiArg = {
  webhookId: number
}
export type GetZapierWebhooksSamplesByTriggerApiResponse =
  /** status 200 OK */ Lead[]
export type GetZapierWebhooksSamplesByTriggerApiArg = {
  trigger: "LEAD_EXPORT"
}
export type GetZapierActionsCreateLeadApiResponse = /** status 200 OK */ any
export type GetZapierActionsCreateLeadApiArg = {
  createLead: CreateLead
}
export type GetConnectorsApiResponse = /** status 200 OK */ Connector[]
export type GetConnectorsApiArg = void
export type GetAuthWsApiResponse = /** status 200 OK */ WsUrl
export type GetAuthWsApiArg = {
  v?: string
}
export type DeleteConnectorsByConnectorIdApiResponse = /** status 204 OK */ void
export type DeleteConnectorsByConnectorIdApiArg = {
  connectorId: string
}
export type PostConnectorsByConnectorIdApiResponse = /** status 204 OK */ void
export type PostConnectorsByConnectorIdApiArg = {
  connectorId: string
  connectorUpdate: ConnectorUpdate
}
export type GetConnectorsByConnectorIdMappingsApiResponse =
  /** status 200 OK */ Mappings
export type GetConnectorsByConnectorIdMappingsApiArg = {
  connectorId: string
}
export type PostConnectorsByConnectorIdStatusMappingsApiResponse =
  /** status 204 OK */ void
export type PostConnectorsByConnectorIdStatusMappingsApiArg = {
  connectorId: string
  statusMappings: StatusMappings
}
export type PostCrawlerHooksCrawlApiResponse = /** status 204 OK */ void
export type PostCrawlerHooksCrawlApiArg = {
  body: string[]
}
export type GetCrmCustomFieldsApiResponse = /** status 200 OK */ CustomField[]
export type GetCrmCustomFieldsApiArg = void
export type PostCrmCustomFieldsApiResponse = /** status 200 OK */ CustomField
export type PostCrmCustomFieldsApiArg = {
  createCustomField: CreateCustomField
}
export type DeleteCrmCustomFieldsByFieldIdApiResponse =
  /** status 204 OK */ void
export type DeleteCrmCustomFieldsByFieldIdApiArg = {
  fieldId: string
}
export type PostCrmCustomFieldsByFieldIdApiResponse = /** status 204 OK */ void
export type PostCrmCustomFieldsByFieldIdApiArg = {
  fieldId: string
  updateCustomField: UpdateCustomField
}
export type GetExportTargetsApiResponse = /** status 200 OK */ TargetList
export type GetExportTargetsApiArg = void
export type PostExportTargetsByTargetIdSetDefaultApiResponse =
  /** status 204 OK */ void
export type PostExportTargetsByTargetIdSetDefaultApiArg = {
  targetId: string
}
export type GetImportsApiResponse = /** status 200 OK */ FileImportSimplified[]
export type GetImportsApiArg = void
export type PostImportsApiResponse = /** status 200 OK */ FileImportSimplified
export type PostImportsApiArg = {
  fileName: string
  body: Blob
}
export type GetImportsByImportIdApiResponse = /** status 200 OK */ FileImport
export type GetImportsByImportIdApiArg = {
  importId: string
}
export type PostImportsByImportIdUpdateMappingsApiResponse =
  /** status 204 OK */ void
export type PostImportsByImportIdUpdateMappingsApiArg = {
  importId: string
  mappings1: Mappings1
}
export type PostImportsByImportIdUpdateFieldMappingsApiResponse =
  /** status 204 OK */ void
export type PostImportsByImportIdUpdateFieldMappingsApiArg = {
  importId: string
  fieldMappings: FieldMappings
}
export type PostInteractionsApiResponse = /** status 204 OK */ void
export type PostInteractionsApiArg = {
  body: Interaction[]
}
export type GetLeadsLikesApiResponse =
  /** status 200 OK */ LeadsLikesGet200Response
export type GetLeadsLikesApiArg = {
  count?: number
  page?: number
}
export type GetLeadsDislikesApiResponse =
  /** status 200 OK */ LeadsLikesGet200Response
export type GetLeadsDislikesApiArg = {
  count?: number
  page?: number
}
export type PostLeadsByLeadIdLikeApiResponse = /** status 204 OK */ void
export type PostLeadsByLeadIdLikeApiArg = {
  leadId: string
}
export type PostLeadsByLeadIdDislikeApiResponse = /** status 204 OK */ void
export type PostLeadsByLeadIdDislikeApiArg = {
  leadId: string
}
export type PostLeadsByLeadIdClearLikeApiResponse = /** status 204 OK */ void
export type PostLeadsByLeadIdClearLikeApiArg = {
  leadId: string
}
export type PostLeadsByLeadIdSetStatusApiResponse = /** status 204 OK */ void
export type PostLeadsByLeadIdSetStatusApiArg = {
  leadId: string
  setStatusRequest: SetStatusRequest
}
export type GetLensesApiResponse = /** status 200 OK */ Lens[]
export type GetLensesApiArg = void
export type PostLensesApiResponse = /** status 200 OK */ Lens
export type PostLensesApiArg = {
  createLens: CreateLens
}
export type DeleteLensesByLensIdApiResponse = /** status 204 OK */ void
export type DeleteLensesByLensIdApiArg = {
  lensId: string
}
export type GetLensesByLensIdApiResponse = /** status 200 OK */ Lens
export type GetLensesByLensIdApiArg = {
  lensId: string
}
export type PostLensesByLensIdApiResponse = /** status 204 OK */ void
export type PostLensesByLensIdApiArg = {
  lensId: string
  updateLens: UpdateLens
}
export type PostLensesByLensIdDraftApiResponse = /** status 200 OK */ Lens
export type PostLensesByLensIdDraftApiArg = {
  lensId: string
}
export type PostLensesByLensIdPromoteApiResponse = /** status 204 OK */ void
export type PostLensesByLensIdPromoteApiArg = {
  lensId: string
}
export type PostLensesByLensIdDemoteApiResponse = /** status 204 OK */ void
export type PostLensesByLensIdDemoteApiArg = {
  lensId: string
}
export type GetLensesByLensIdFilterApiResponse = /** status 200 OK */ Filter
export type GetLensesByLensIdFilterApiArg = {
  lensId: string
}
export type PostLensesByLensIdFilterApiResponse = /** status 204 OK */ void
export type PostLensesByLensIdFilterApiArg = {
  lensId: string
  filter: Filter
}
export type GetLensesByLensIdScoringApiResponse =
  /** status 200 OK */ ScoringConfig
export type GetLensesByLensIdScoringApiArg = {
  lensId: string
}
export type PostLensesByLensIdScoringApiResponse = /** status 204 OK */ void
export type PostLensesByLensIdScoringApiArg = {
  lensId: string
  scoringConfig: ScoringConfig
}
export type GetLensesByLensIdLeadsApiResponse =
  /** status 200 OK */ LensesLensIdLeadsGet200Response
export type GetLensesByLensIdLeadsApiArg = {
  lensId: string
  wished?: boolean
  status?: LeadStatus
  owner?: string
  q?: string
  count?: number
  page?: number
  order?: LeadOrder[]
}
export type GetLensesByLensIdLeadsNewCountsApiResponse =
  /** status 200 OK */ NewCounts
export type GetLensesByLensIdLeadsNewCountsApiArg = {
  lensId: string
}
export type GetLensesByLensIdLeadsOnboardingApiResponse =
  /** status 200 OK */ Lead1[]
export type GetLensesByLensIdLeadsOnboardingApiArg = {
  lensId: string
}
export type GetLensesByLensIdLeadsTrashApiResponse =
  /** status 200 OK */ LensesLensIdLeadsTrashGet200Response
export type GetLensesByLensIdLeadsTrashApiArg = {
  lensId: string
  q?: string
  count?: number
  page?: number
  order?: LeadOrder[]
}
export type GetLensesByLensIdLeadsWishlistApiResponse =
  /** status 200 OK */ LensesLensIdLeadsWishlistGet200Response
export type GetLensesByLensIdLeadsWishlistApiArg = {
  lensId: string
  q?: string
  count?: number
  page?: number
  order?: LeadOrder[]
}
export type GetLensesByLensIdLeadsAndLeadIdApiResponse =
  /** status 200 OK */ FullLead
export type GetLensesByLensIdLeadsAndLeadIdApiArg = {
  lensId: string
  leadId: string
}
export type GetLensesByLensIdLeadsAndLeadIdPredictStatusApiResponse =
  /** status 200 OK */ LeadStatusPrediction
export type GetLensesByLensIdLeadsAndLeadIdPredictStatusApiArg = {
  lensId: string
  leadId: string
}
export type GetLensesByLensIdLeadsAndLeadIdSimilarPastApiResponse =
  /** status 200 OK */ Lead1[]
export type GetLensesByLensIdLeadsAndLeadIdSimilarPastApiArg = {
  lensId: string
  leadId: string
}
export type GetLensesByLensIdLeadsAndLeadIdSimilarWishedApiResponse =
  /** status 200 OK */ Lead1[]
export type GetLensesByLensIdLeadsAndLeadIdSimilarWishedApiArg = {
  lensId: string
  leadId: string
}
export type PostLensesByLensIdLeadsAndLeadIdUntrashApiResponse =
  /** status 204 OK */ void
export type PostLensesByLensIdLeadsAndLeadIdUntrashApiArg = {
  lensId: string
  leadId: string
}
export type PostLensesByLensIdExportMonitorApiResponse =
  /** status 200 OK */ Blob
export type PostLensesByLensIdExportMonitorApiArg = {
  lensId: string
  body: string[]
}
export type PostLensesByLensIdExportMonitorAllApiResponse =
  /** status 200 OK */ Blob
export type PostLensesByLensIdExportMonitorAllApiArg = {
  lensId: string
}
export type PostLensesByLensIdExportWishlistApiResponse =
  /** status 200 OK */ DoExportResponse
export type PostLensesByLensIdExportWishlistApiArg = {
  lensId: string
  doExportRequest: DoExportRequest
}
export type PostLensesByLensIdExportWishlistAllApiResponse =
  /** status 200 OK */ DoExportResponse
export type PostLensesByLensIdExportWishlistAllApiArg = {
  lensId: string
  doExportAllRequest: DoExportAllRequest
}
export type GetOrganizationsByOrgIdFeatureFlagsApiResponse =
  /** status 200 OK */ FeatureFlag[]
export type GetOrganizationsByOrgIdFeatureFlagsApiArg = {
  orgId: string
}
export type GetTimelineBlocksApiResponse =
  /** status 200 OK */ TimelineBlocksGet200Response
export type GetTimelineBlocksApiArg = {
  count?: number
  page?: number
}
export type PostTimelineBlocksByBlockIdExportLeadsApiResponse =
  /** status 200 OK */ DoExportResponse
export type PostTimelineBlocksByBlockIdExportLeadsApiArg = {
  blockId: string
  doExportRequest: DoExportRequest
}
export type GetTimelineNewCountApiResponse = /** status 200 OK */ number
export type GetTimelineNewCountApiArg = void
export type AuthInvitesInviteIdGet200Response = {
  email: string
  organization_name?: string
  user_name?: string
}
export type ErrorResponseError = {
  code: string
  message?: string
}
export type ErrorResponse = {
  error: ErrorResponseError
}
export type AuthRegisterPost200Response = {
  token: string
}
export type UserRegistration = {
  email: string
  password: string
  name: string
  invite?: string
}
export type CheckEmail = {
  email: string
}
export type EmailVerifyCode = {
  code: string
}
export type AuthLoginPost200Response = {
  token: string
  /** Whether the user has verified their email address. */
  verified: boolean
}
export type UserLogin = {
  email: string
  password: string
}
export type AuthLogoutPostRequest = {
  /** Invalidate all tokens */
  all?: boolean
}
export type AuthInvitePostRequest = {
  email: string
}
export type InvitesStatus = {
  remaining: number
}
export type PasswordChange = {
  current: string
  new: string
}
export type AuthPasswordResetStartPostRequest = {
  email: string
}
export type PasswordReset = {
  new: string
}
export type GoogleLoginResponse = {
  registered: boolean
  logged_in: boolean
  token?: string
}
export type GoogleUserRegistration = {
  email: string
  password?: string
  name: string
  invite?: string
}
export type GoogleLink = {
  id_token: string
}
export type LeadSimplifiedSize = {
  min: number
  max?: number
}
export type LeadSimpleLocation = {
  city?: string
  state?: string
  /** ISO 3166-1 alpha-2 code */
  country: string
  pos?: number[]
}
export type LeadStatus =
  | "DEFAULT"
  | "INBOUND"
  | "UNWANTED"
  | "WANTED"
  | "LOST"
  | "WON"
export type LeadState = {
  status: LeadStatus
  owner?: string
}
export type LeadSimplified = {
  id: string
  name: string
  sector?: string
  size?: LeadSimplifiedSize
  website?: string
  location: LeadSimpleLocation
  description?: string
  description_source?: string
  logo?: string
  state?: LeadState
}
export type OAuthApplication = {
  id: string
  name: string
  redirect_uris: string[]
  logo: string
}
export type OAuthCreateGrantResponse = {
  code: string
}
export type OAuthCreateGrant = {
  application_id: string
  redirect_uri?: string
}
export type OAuthAccessTokenResponse = {
  access_token: string
  token_type: string
}
export type OAuthError = {
  error:
    | "invalid_request"
    | "invalid_client"
    | "invalid_grant"
    | "unsupported_grant_type"
  error_description?: string
}
export type User = {
  id: string
  verified: boolean
  admin: boolean
  email: string
  name: string
  computing_daily_timeline: boolean
  computing_daily_wishlist: boolean
}
export type BillingStatus = "NOT_SET_UP" | "OK" | "ACTION_NEEDED"
export type BillingState = {
  status: BillingStatus
  /** When status is not OK, this is the deadline for fixing issues before the organization is suspended. */
  deadline?: string
  /** Whether the organization is suspended for billing issues. This prevents using the app. */
  suspended: boolean
  /** Whether the organization has more users than seats. This prevents using the app. */
  missing_seat: boolean
  /** currently billed seats (max users in the organization) */
  seats: number
}
export type Organization = {
  id: string
  name: string
  website?: string
  size?: number
  industry?: string
  location?: string
  description?: string
  /** Whether organization info has been completed, either automatically or by user submission */
  completed: boolean
  onboarding_step:
    | "ORGANIZATION_INFO"
    | "CRM"
    | "ICP"
    | "PROCESSING"
    | "FINISHED"
  billing: BillingState
}
export type UserWithOrg = User & {
  organization: Organization
}
export type OrgUpdateAnyOf = {
  name: string
}
export type OrgUpdateAnyOf1 = {
  website: string
}
export type OrgUpdateAnyOf2 = {
  size: number
}
export type OrgUpdateAnyOf3 = {
  industry: string
}
export type OrgUpdateAnyOf4 = {
  location: string
}
export type OrgUpdateAnyOf5 = {
  description: string
}
export type OrgUpdate =
  | OrgUpdateAnyOf
  | OrgUpdateAnyOf1
  | OrgUpdateAnyOf2
  | OrgUpdateAnyOf3
  | OrgUpdateAnyOf4
  | OrgUpdateAnyOf5
export type InviteCreation = {
  email: string
  admin: boolean
}
export type Invite = {
  id: string
  used: boolean
} & InviteCreation
export type OrganizationsOrgIdInvitesInviteIdPostRequest = {
  admin: boolean
}
export type LeadLocation = {
  city?: string
  state?: string
  /** ISO 3166-1 alpha-2 code */
  country: string
}
export type StripeCheckoutGet200Response = {
  url: string
}
export type Webhook = {
  id: number
  connector_id: number
  trigger: "LEAD_EXPORT"
}
export type CreateWebhook = {
  hook_url: string
  trigger: "LEAD_EXPORT"
}
export type Lead = {
  id: string
  crm_id?: string
  name: string
  sector?: string
  size?: string
  website?: string
  location: string
  description?: string
  status?: LeadStatus
  score?: number
}
export type CreateLead = {
  crm_id?: string
  leadbay_id?: string
  name: string
  status: string
  sector?: string
  size?: string
  website?: string
  location?: string
}
export type Connector = {
  id: string
  type: "ZAPIER"
  name: string
  imports: number
  last_import?: string
}
export type WsUrl = {
  url: string
  expires_in: number
}
export type ConnectorUpdate = {
  name: string
}
export type Mappings = {
  statuses: {
    [key: string]: LeadStatus
  }
  status_samples?: string[]
}
export type StatusMappings = {
  statuses: {
    [key: string]: LeadStatus
  }
}
export type CustomField = {
  id: string
  name: string
}
export type CreateCustomField = {
  name: string
}
export type UpdateCustomField = {
  name: string
}
export type Target = {
  id: string
  type: "CONNECTOR" | "FILE"
  /** Whether this target can currently be used to export leads */
  available?: boolean
  connector?: Connector
}
export type TargetList = {
  targets: Target[]
  /** ID of the default export target, if available */
  default?: string
  /** ID of the last used export target, if available */
  last?: string
}
export type FileImportSimplified = {
  id: string
  date: string
  file_name: string
}
export type StandardFieldType =
  | "CRM_ID"
  | "EMAIL"
  | "LEAD_LOCATION"
  | "LEAD_LOCATION_STREET_NUM"
  | "LEAD_LOCATION_STREET"
  | "LEAD_LOCATION_POSTCODE"
  | "LEAD_LOCATION_CITY"
  | "LEAD_NAME"
  | "LEAD_SECTOR"
  | "LEAD_SIZE"
  | "LEAD_STATUS"
  | "LEAD_WEBSITE"
  | "LEADBAY_ID"
export type FieldType = StandardFieldType | string
export type Mappings1 = {
  fields: {
    [key: string]: FieldType
  }
  statuses: {
    [key: string]: LeadStatus
  }
  default_status?: LeadStatus
}
export type PreProcessingState = {
  /** Whether CSV pre-processing is finished and successful */
  finished: boolean
  /** Optional pre-processing error */
  error?: string
  hints?: Mappings1
  samples?: {
    [key: string]: string
  }[]
  status_samples?: string[]
}
export type ProcessingState = {
  progress?: number
  /** Whether CSV processing is finished and successful */
  finished: boolean
  /** Optional processing error */
  error?: string
}
export type FileImport = FileImportSimplified & {
  mappings?: Mappings1
  pre_processing?: PreProcessingState
  processing?: ProcessingState
}
export type FieldMappings = {
  fields: {
    [key: string]: FieldType
  }
}
export type BlockLeadClickedInteraction = {
  type: "BLOCK_LEAD_CLICKED"
  lead_id: string
  block_id: string
}
export type BlockLeadSeenInteraction = {
  type: "BLOCK_LEAD_SEEN"
  lead_id: string
  block_id: string
}
export type LeadClickedInteraction = {
  type: "LEAD_CLICKED"
  lead_id: string
  lens_id: string
}
export type LeadSeenInteraction = {
  type: "LEAD_SEEN"
  lead_id: string
  lens_id: string
}
export type LeadWebsiteClickedInteraction = {
  type: "LEAD_WEBSITE_CLICKED"
  lead_id: string
}
export type Interaction =
  | BlockLeadClickedInteraction
  | BlockLeadSeenInteraction
  | LeadClickedInteraction
  | LeadSeenInteraction
  | LeadWebsiteClickedInteraction
export type Pagination = {
  /** Current page */
  page: number
  /** Number of pages */
  pages: number
  /** Total number of items */
  total: number
}
export type LeadsLikesGet200Response = {
  items: LeadSimplified[]
  pagination: Pagination
}
export type SetStatusRequest = {
  status?: LeadStatus
}
export type Lens = {
  id: string
  /** If missing, the lens is org-level and can only be modified by admins. */
  user_id?: string
  /** True if the lens is the default lens of the organization. The default lens is a special lens that always contains
    all leads of an organization. The filter of the default lead cannot be modified by anyone and is always empty.
     */
  default: boolean
  /** ID of the parent lens, if this lens is a draft. */
  draft_of?: string
  name: string
  description?: string
}
export type CreateLens = {
  /** ID of the lens to use as a base. The new lens will inherit the base lens filter and scoring config. */
  base: string
  name: string
  description?: string
}
export type UpdateLens = {
  name?: string
  description?: string
}
export type FilterCriterionAnyOf = {
  type: "size"
  sizes: LeadSimplifiedSize[]
}
export type FilterCriterionAnyOf1 = {
  type: "keywords"
  keywords: string[]
}
export type FilterCriterionAnyOf2 = {
  type: "sectors"
  sectors: string[]
}
export type FilterCriterionAnyOf3LocationsInner = {
  city?: string
  state?: string
  /** ISO 3166-1 alpha-2 code */
  country: string
}
export type FilterCriterionAnyOf3 = {
  type: "location"
  locations: FilterCriterionAnyOf3LocationsInner[]
}
export type FilterCriterionAnyOf4 = {
  type: "custom_field"
  field: string
  values: string[]
}
export type FilterCriterion =
  | FilterCriterionAnyOf
  | FilterCriterionAnyOf1
  | FilterCriterionAnyOf2
  | FilterCriterionAnyOf3
  | FilterCriterionAnyOf4
export type FilterItem = {
  criteria: FilterCriterion[]
}
export type Filter = {
  items: FilterItem[]
}
export type ScoringCriterion = {
  importance: number
} & (
  | {
      type: "size"
      sizes: {
        min: number
        max?: number
      }[]
    }
  | {
      type: "keywords"
      keywords: string[]
    }
  | {
      type: "similar_to_status"
      status: LeadStatus
    }
  | {
      type: "sectors"
      sectors: string[]
    }
  | {
      type: "location"
      locations: LeadLocation[]
    }
)
export type ScoringConfig = {
  criteria: ScoringCriterion[]
}
export type LeadKeyword = {
  keyword: string
  rarity: number
  importance: number
  highlighted: boolean
}
export type Lead1 = LeadSimplified & {
  keywords: LeadKeyword[]
  score?: number
  highlighted_fields: ("LOCATION" | "SECTOR" | "SIZE")[]
}
export type LeadSimpleStatusPrediction = {
  status: LeadStatus
  changed: boolean
}
export type NewableLeadAllOfOrigin = {
  type: "CRM" | "EXPORTED"
  date: string
}
export type NewableLead = Lead1 & {
  new: boolean
  liked: boolean
  disliked: boolean
  stale_at?: string
  status_prediction?: LeadSimpleStatusPrediction
  origin?: NewableLeadAllOfOrigin
}
export type LensesLensIdLeadsGet200Response = {
  items: NewableLead[]
  pagination: Pagination
  computing_scores: boolean
}
export type LeadOrder =
  | "DISLIKED:ASC"
  | "DISLIKED:DESC"
  | "ID:ASC"
  | "ID:DESC"
  | "LIKED:ASC"
  | "LIKED:DESC"
  | "NAME:ASC"
  | "NAME:DESC"
  | "SCORE:ASC"
  | "SCORE:DESC"
  | "SECTOR:ASC"
  | "SECTOR:DESC"
  | "SIZE:ASC"
  | "SIZE:DESC"
  | "STATUS:ASC"
  | "STATUS:DESC"
  | "WEBSITE:ASC"
  | "WEBSITE:DESC"
export type NewCounts = {
  wished: number
  monitor: number
}
export type LensesLensIdLeadsTrashGet200Response = {
  items: NewableLead[]
  pagination: Pagination
}
export type LensesLensIdLeadsWishlistGet200Response = {
  items: NewableLead[]
  pagination: Pagination
  computing_scores: boolean
  computing_wishlist: boolean
}
export type CustomFieldValue = {
  field: CustomField
  value: string
}
export type LeadFullLocation = {
  city?: string
  state?: string
  /** ISO 3166-1 alpha-2 code */
  country: string
  pos?: number[]
  full?: string
}
export type FullLeadAllOfRegistryIds = {
  SIRENE?: string
}
export type FullLead = NewableLead & {
  custom_fields: CustomFieldValue[]
  location: LeadFullLocation
  registry_ids: FullLeadAllOfRegistryIds
}
export type LeadStatusPrediction = {
  status?: LeadStatus
  probability?: number
  changed: boolean
  explanations?: string[]
}
export type DoExportResponse = {
  /** Optional error description, always missing if `success` is true. */
  error?: string
  /** Set to true if the leads were successfully exported. If set to false, it usually indicates there is
    something wrong with the export target.
     */
  success: boolean
}
export type DoExportRequest = {
  target_id: string
  lead_ids: string[]
}
export type DoExportAllRequest = {
  target_id: string
}
export type FeatureFlag = "HUBSPOT" | "MAP_VIEW"
export type HoroscopeTimelineBlock = {
  type: "horoscope"
  id: string
  created_on: string
  horoscope: string
}
export type LeadsTimelineBlockLeadsInner = LeadSimplified & {
  exported: boolean
}
export type LeadsTimelineBlock = {
  type: "leads"
  id: string
  created_on: string
  title: string
  leads: LeadsTimelineBlockLeadsInner[]
}
export type TimelineBlock = HoroscopeTimelineBlock | LeadsTimelineBlock
export type TimelineBlocksGet200Response = {
  items: TimelineBlock[]
  pagination: Pagination
}
export const {
  useGetAuthInvitesByInviteIdQuery,
  usePostAuthRegisterMutation,
  usePostAuthCheckEmailMutation,
  usePostAuthResendEmailMutation,
  usePostAuthVerifyEmailMutation,
  usePostAuthVerifyEmailCodeMutation,
  usePostAuthLoginMutation,
  usePostAuthLogoutMutation,
  usePostAuthInviteMutation,
  useGetAuthInvitesStatusQuery,
  usePostAuthChangePasswordMutation,
  usePostAuthPasswordResetStartMutation,
  usePostAuthPasswordResetChangePasswordMutation,
  usePostAuthGoogleLoginMutation,
  usePostAuthGoogleRegisterMutation,
  usePostAuthGoogleLinkMutation,
  useGetAuthSearchOrgsQuery,
  useGetOauthApplicationsByAppIdQuery,
  usePostOauthGrantsMutation,
  usePostOauthTokenMutation,
  useDeleteUsersMeMutation,
  useGetUsersMeQuery,
  useDeleteOrganizationsByOrgIdMutation,
  useGetOrganizationsByOrgIdQuery,
  usePostOrganizationsByOrgIdMutation,
  useGetOrganizationsByOrgIdInvitesQuery,
  usePostOrganizationsByOrgIdInvitesMutation,
  useDeleteOrganizationsByOrgIdInvitesAndInviteIdMutation,
  usePostOrganizationsByOrgIdInvitesAndInviteIdMutation,
  useGetOrganizationsByOrgIdUsersQuery,
  useDeleteOrganizationsByOrgIdUsersAndUserIdMutation,
  usePostOrganizationsByOrgIdUsersAndUserIdMutation,
  useGetSectorsAllQuery,
  useGetGeoSearchQuery,
  useGetKeywordsSearchQuery,
  useGetStripeCheckoutQuery,
  useGetStripePortalQuery,
  useGetZapierTestQuery,
  usePostZapierWebhooksMutation,
  useDeleteZapierWebhooksByWebhookIdMutation,
  useGetZapierWebhooksSamplesByTriggerQuery,
  useGetZapierActionsCreateLeadQuery,
  useGetConnectorsQuery,
  useGetAuthWsQuery,
  useDeleteConnectorsByConnectorIdMutation,
  usePostConnectorsByConnectorIdMutation,
  useGetConnectorsByConnectorIdMappingsQuery,
  usePostConnectorsByConnectorIdStatusMappingsMutation,
  usePostCrawlerHooksCrawlMutation,
  useGetCrmCustomFieldsQuery,
  usePostCrmCustomFieldsMutation,
  useDeleteCrmCustomFieldsByFieldIdMutation,
  usePostCrmCustomFieldsByFieldIdMutation,
  useGetExportTargetsQuery,
  usePostExportTargetsByTargetIdSetDefaultMutation,
  useGetImportsQuery,
  usePostImportsMutation,
  useGetImportsByImportIdQuery,
  usePostImportsByImportIdUpdateMappingsMutation,
  usePostImportsByImportIdUpdateFieldMappingsMutation,
  usePostInteractionsMutation,
  useGetLeadsLikesQuery,
  useGetLeadsDislikesQuery,
  usePostLeadsByLeadIdLikeMutation,
  usePostLeadsByLeadIdDislikeMutation,
  usePostLeadsByLeadIdClearLikeMutation,
  usePostLeadsByLeadIdSetStatusMutation,
  useGetLensesQuery,
  usePostLensesMutation,
  useDeleteLensesByLensIdMutation,
  useGetLensesByLensIdQuery,
  usePostLensesByLensIdMutation,
  usePostLensesByLensIdDraftMutation,
  usePostLensesByLensIdPromoteMutation,
  usePostLensesByLensIdDemoteMutation,
  useGetLensesByLensIdFilterQuery,
  usePostLensesByLensIdFilterMutation,
  useGetLensesByLensIdScoringQuery,
  usePostLensesByLensIdScoringMutation,
  useGetLensesByLensIdLeadsQuery,
  useGetLensesByLensIdLeadsNewCountsQuery,
  useGetLensesByLensIdLeadsOnboardingQuery,
  useGetLensesByLensIdLeadsTrashQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
  useGetLensesByLensIdLeadsAndLeadIdQuery,
  useGetLensesByLensIdLeadsAndLeadIdPredictStatusQuery,
  useGetLensesByLensIdLeadsAndLeadIdSimilarPastQuery,
  useGetLensesByLensIdLeadsAndLeadIdSimilarWishedQuery,
  usePostLensesByLensIdLeadsAndLeadIdUntrashMutation,
  usePostLensesByLensIdExportMonitorMutation,
  usePostLensesByLensIdExportMonitorAllMutation,
  usePostLensesByLensIdExportWishlistMutation,
  usePostLensesByLensIdExportWishlistAllMutation,
  useGetOrganizationsByOrgIdFeatureFlagsQuery,
  useGetTimelineBlocksQuery,
  usePostTimelineBlocksByBlockIdExportLeadsMutation,
  useGetTimelineNewCountQuery,
} = injectedRtkApi

import {
  leadbayApi,
  NewableLead,
  useGetLensesByLensIdLeadsNewCountsQuery,
  useGetLensesByLensIdLeadsQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
  type Interaction,
} from "@api/leadbayApi"
import { LbWishlistGrid } from "@components/display/LbWishlistGrid/LbWishlistGrid"
import { LbDataGridSearch } from "@components/inputs/LbDataGridSearch/LbDataGridSearch"
import {
  APP_DRAWER_WIDTH,
  CENTERED_FLEX_COL,
  CENTERED_FLEX_ROW,
} from "@constants/index"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { useRecordUserInteractions } from "@hooks/useRecordUserInteractions"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import { type GridRowSelectionModel } from "@mui/x-data-grid"
import { selectAuthState } from "@redux/authSlice"
import {
  selectCommonsState,
  setCurrentFilter,
  setPaginationModel,
  toggleNavDrawer,
} from "@redux/commonsSlice"
import { generateWishlistRows } from "@utils/generateWishListRows"
import { useAsyncEffect } from "ahooks"
import { motion } from "framer-motion"
import localforage from "localforage"
import type React from "react"
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ChangeEvent,
  type SyntheticEvent,
} from "react"
import { toast } from "react-toastify"
import { TimelineScreen } from "../TimelineScreen/TimelineScreen"
import { useKeyboardController } from "./use-keyboard-controller"

const WishlistScreen = () => {
  const dispatch = useAppDispatch()

  const { user } = useAppSelector(selectAuthState)
  const { currentLensId, selectedIds } = useAppSelector(selectCommonsState)
  const [enableSelection, setEnableSelection] = useState(false)
  const [checkMonitorComputing, setCheckMonitorComputing] = useState<
    undefined | number
  >(undefined)
  const [checkDiscoverComputing, setCheckDiscoverComputing] = useState<
    undefined | number
  >(undefined)

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  const {
    drawerData,
    wishlistViewMode,
    tempLikedLeads,
    paginationModel,
    pageSize,
    currentFilter,
    sortModel,
  } = useAppSelector(selectCommonsState)

  const { refetch: refetchCountData } = useGetLensesByLensIdLeadsNewCountsQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const {
    data,
    isFetching,
    isLoading,
    refetch: refetchDiscover,
    error,
  } = useGetLensesByLensIdLeadsWishlistQuery(
    {
      lensId: currentLensId,
      count: pageSize,
      page: paginationModel.page,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
      pollingInterval: checkDiscoverComputing,
    },
  )

  const {
    data: leadData,
    isFetching: leadIsFetching,
    isLoading: dataLoading,
    refetch: refetchMonitor,
    error: leadError,
  } = useGetLensesByLensIdLeadsQuery(
    {
      lensId: currentLensId,
      page: paginationModel.page,
      count: pageSize,
      wished: false,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
      pollingInterval: checkMonitorComputing,
    },
  )

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const [pageViewHistory, setPageViewHistory] = useState<number[]>([])

  useAsyncEffect(async () => {
    if (
      data?.items &&
      !checkMonitorComputing &&
      wishlistViewMode !== "timeline" &&
      !isFetching &&
      !leadIsFetching &&
      !pageViewHistory.includes(paginationModel.page)
    ) {
      const interactions = data?.items.map((item) => ({
        type: "LEAD_SEEN",
        lead_id: item.id,
        lens_id: String(currentLensId),
      })) as Interaction[]

      await handleRecordUserInteractions(interactions)

      setPageViewHistory([...pageViewHistory, paginationModel.page])
    }
  }, [
    data?.items,
    paginationModel.page,
    isFetching,
    leadIsFetching,
    checkMonitorComputing,
    wishlistViewMode,
  ])

  useEffect(() => {
    if (error) {
      // @ts-expect-error Disabling TS error
      toast.error((error?.data?.error?.code as string) ?? "An error occurred")
    }

    if (leadError) {
      // @ts-expect-error Disabling TS error
      toast.error((error?.data?.error?.code as string) ?? "An error occurred")
    }
  }, [error, leadError])

  const [rowCountState, setRowCountState] = useState(
    wishlistViewMode === "discover"
      ? data?.pagination.total || 0
      : leadData?.pagination.pages || 0,
  )

  useEffect(() => {
    if (!pageSize) return

    dispatch(
      setPaginationModel({
        pageSize,
        page: 0,
      }),
    )
  }, [wishlistViewMode, pageSize])

  const { navDrawerIsOpen, navDrawerPartial } =
    useAppSelector(selectCommonsState)

  const handleToggleFilters = useCallback(() => {
    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: "WISHLIST_FILTERS",
      }),
    )
  }, [])

  const handleToggleScoringParameters = useCallback(() => {
    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: "SCORING_PARAMETERS",
      }),
    )
  }, [])

  const getWishListData = useMemo(
    () => (wishlistViewMode === "discover" ? data : leadData),
    [wishlistViewMode, data, leadData],
  )

  const tablesRows = useMemo(() => {
    if (!getWishListData?.items) return []
    else return getWishListData?.items
  }, [getWishListData])

  const rows = useMemo(
    () =>
      generateWishlistRows(tablesRows, tempLikedLeads).map((row) => ({
        ...row,
        new: selectedIds.includes(row.id) ? false : row.new,
      })),
    [tablesRows, selectedIds, tempLikedLeads],
  )

  useEffect(() => {
    if (wishlistViewMode !== "timeline")
      dispatch(
        toggleNavDrawer({
          isOpen: true,
          partial: "SCORING_PARAMETERS",
          data: drawerData,
        }),
      )
  }, [wishlistViewMode])

  useEffect(() => {
    if (wishlistViewMode === "monitor") {
      setRowCountState((prevRowCountState) =>
        leadData?.pagination.total !== undefined
          ? leadData?.pagination.total
          : prevRowCountState,
      )
    } else {
      setRowCountState((prevRowCountState) =>
        data?.pagination.total !== undefined
          ? data?.pagination.total
          : prevRowCountState,
      )
    }
  }, [leadData?.pagination.total, wishlistViewMode, data?.pagination.total])

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setCurrentFilter(event.target.value))
    },
    [],
  )

  const handleToggleDrawer = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      event.preventDefault()

      dispatch(
        toggleNavDrawer({
          isOpen: !navDrawerIsOpen,
          partial: navDrawerPartial,
          data: drawerData,
        }),
      )
    },
    [navDrawerIsOpen, navDrawerPartial, drawerData],
  )

  useEffect(() => {
    const refetchDiscover =
      data?.computing_scores ||
      data?.computing_wishlist ||
      user?.computing_daily_wishlist
    const refetchMonitor = leadData?.computing_scores

    if (refetchDiscover || refetchMonitor) {
      dispatch(leadbayApi.util.invalidateTags(["Leads"]))

      dispatch(
        setPaginationModel({
          page: 0,
          pageSize: pageSize ?? 10,
        }),
      )
    }

    setCheckDiscoverComputing(refetchDiscover ? 2000 : undefined)
    setCheckMonitorComputing(refetchMonitor ? 2000 : undefined)
  }, [
    user?.computing_daily_wishlist,
    data?.computing_scores,
    data?.computing_wishlist,
    leadData?.computing_scores,
    pageSize,
  ])

  const { dataGridRef } = useKeyboardController({
    page: paginationModel.page,
    rows,
  })

  const showLoader =
    checkDiscoverComputing === 2000 || checkMonitorComputing === 2000

  const showSkeleton = !dataLoading && !isLoading

  const showFullLoader = showLoader || isFetching || leadIsFetching

  const selectedLeadsData = useMemo(
    () =>
      tablesRows.filter((row) =>
        rowSelectionModel.includes(row.id),
      ) as unknown as NewableLead[],
    [rowSelectionModel, rows],
  )

  let content: React.ReactElement
  if (wishlistViewMode === "timeline") {
    content = <TimelineScreen />
  } else {
    content = (
      <Box
        id={`${wishlistViewMode}-screen`}
        sx={{
          position: "relative",
          flex: 1,
          minWidth: "0",
          display: "flex",
          flexDirection: "column",
          cursor: showFullLoader ? "wait" : "auto",
          grayScale: showFullLoader ? 1 : 0,
        }}
      >
        {tablesRows.length > 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            style={{
              width: "100%",
              height: "100%",
              pointerEvents: showFullLoader ? "none" : "auto",
            }}
          >
            <LbWishlistGrid
              dataGridRef={dataGridRef}
              rows={rows}
              selectionEnabled={enableSelection}
              rowCount={rowCountState}
              rowSelectionModel={{ rowSelectionModel, setRowSelectionModel }}
              showFullLoader={showFullLoader}
              showLoader={showLoader}
            />
          </motion.div>
        ) : (
          <motion.div>
            <Box
              sx={{
                ...CENTERED_FLEX_COL,

                width: "100%",
                height: "calc(100vh - 90px)",
              }}
            >
              <Box
                sx={{
                  ...CENTERED_FLEX_COL,
                  height: "100%",
                }}
              >
                {showLoader ? (
                  <Box
                    sx={{
                      ...CENTERED_FLEX_ROW,
                      transform: "translateY(-5vh)",
                    }}
                  >
                    <Typography className="animate-pulse">
                      computing lead data...
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      ...CENTERED_FLEX_ROW,
                      transform: "translateY(-5vh)",
                    }}
                  >
                    <Typography sx={{ marginLeft: 1 }}>
                      No leads have been found with this filter. Try changing it
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </motion.div>
        )}
      </Box>
    )
  }

  return showSkeleton ? (
    <Box
      id="dashboard"
      sx={{
        display: "flex",
        alignItems: "center",
        height: "calc(100vh - 30px)",
        position: "relative",
        flex: 1,
      }}
      className="hide-scrollbar"
    >
      <Box
        className="hide-scrollbar"
        component="article"
        sx={{
          height: "calc(100vh - 30px)",
          width: `calc(100vw - ${APP_DRAWER_WIDTH})`,
          maxWidth: "1600px",
          mx: "auto",
          overflowY: "auto",
          flex: 1,
          pr: "40px",
          pl: "16px",
          pt: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LbDataGridSearch
          onSearch={handleSearch}
          onToggleFilters={handleToggleFilters}
          onEnableSelection={(value) =>
            setEnableSelection(value ?? !enableSelection)
          }
          onRefetchWishlist={async () => {
            await refetchDiscover()
            await refetchCountData()
          }}
          onRefetchMonitor={async () => {
            await refetchMonitor()
            await refetchCountData()
          }}
          onToggleScoringParameters={handleToggleScoringParameters}
          onResetSelection={() => {
            setRowSelectionModel([])

            localforage.removeItem("wishlist_row_selection_model")
          }}
          selectedLeadsData={selectedLeadsData}
          searchValue={currentFilter}
          exportData={{
            selectedLeads: rowSelectionModel,
            enableSelection,
          }}
        />

        {content}
      </Box>

      {!user?.computing_daily_timeline && wishlistViewMode !== "timeline" && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.1 }}
        >
          <Box sx={{ position: "absolute", right: 0 }}>
            <IconButton size="small" onClick={handleToggleDrawer}>
              {!navDrawerIsOpen ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </Box>
        </motion.div>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        height: window.innerHeight,
        width: "calc(100vw - 383px)",
        px: "40px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%", mb: 3, mt: 10 }}
        height={50}
      />

      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={window.innerHeight - 300}
      />
    </Box>
  )
}

export default WishlistScreen

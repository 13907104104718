import {
  usePostLeadsByLeadIdClearLikeMutation,
  usePostLeadsByLeadIdDislikeMutation,
  usePostLeadsByLeadIdLikeMutation,
  type FullLead,
} from "@api/leadbayApi"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import {
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material"
import { Box, IconButton, Tooltip } from "@mui/material"
import { selectCommonsState, setTempLikedLeads } from "@redux/commonsSlice"
import { analytics } from "@utils/analytics"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

export type LikeStatus = "like" | "dislike" | "none"

interface LbFavoriteModuleProps {
  lead: FullLead
  refetchLead: () => void
}

export const LbFavoriteModule = ({
  lead,
  refetchLead,
}: LbFavoriteModuleProps) => {
  const { id: leadId, liked, disliked } = lead

  const dispatch = useAppDispatch()

  const { tempLikedLeads } = useAppSelector(selectCommonsState)

  const [likeMutation] = usePostLeadsByLeadIdLikeMutation()
  const [dislikeMutation] = usePostLeadsByLeadIdDislikeMutation()
  const [clearLikeMutation] = usePostLeadsByLeadIdClearLikeMutation()

  const [likeStatus, setLikeStatus] = useState<LikeStatus>("none")

  useEffect(() => {
    const initialLikeStatus = liked ? "like" : disliked ? "dislike" : "none"
    setLikeStatus(initialLikeStatus)
  }, [liked, disliked, tempLikedLeads])

  const handleSetTempLikedLeads = (id: string, liked: LikeStatus) =>
    dispatch(setTempLikedLeads({ id, liked }))

  const handleLikeLead = async () => {
    try {
      if (likeStatus === "like") {
        await handleClearLike()

        setLikeStatus("none")
        handleSetTempLikedLeads(leadId, "none")
      } else {
        await likeMutation({ leadId }).unwrap()
        analytics.vote({ voteType: "like" })

        handleSetTempLikedLeads(leadId, "like")
      }

      refetchLead()
    } catch (error) {
      toast.error("An error occurred while liking the lead")

      console.error(error)
    }
  }

  const handleDislikeLead = async () => {
    try {
      if (likeStatus === "dislike") {
        await handleClearLike()

        setLikeStatus("none")
        handleSetTempLikedLeads(leadId, "none")
      } else {
        await dislikeMutation({ leadId }).unwrap()
        analytics.vote({ voteType: "dislike" })

        setLikeStatus("dislike")
        handleSetTempLikedLeads(leadId, "dislike")
      }

      refetchLead()
    } catch (error) {
      toast.error("An error occurred while disliking the lead")

      console.error(error)
    }
  }

  const handleClearLike = async () => {
    try {
      await clearLikeMutation({ leadId }).unwrap()
    } catch (error) {
      toast.error("An error occurred while clearing the like")

      console.error(error)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,

        transition: "all 0.3s ease-in-out",
      }}
    >
      <Tooltip title="Like leads to receive more tailored recommendations">
        <IconButton onClick={handleLikeLead}>
          {likeStatus === "like" ? (
            <ThumbUp color="primary" sx={{ fontSize: "1.3rem" }} />
          ) : (
            <ThumbUpOutlined color="primary" sx={{ fontSize: "1.3rem" }} />
          )}
        </IconButton>
      </Tooltip>

      <Tooltip title="Dislike leads to receive more tailored recommendations">
        <IconButton onClick={handleDislikeLead}>
          {likeStatus === "dislike" ? (
            <ThumbDown color="primary" sx={{ fontSize: "1.3rem" }} />
          ) : (
            <ThumbDownOutlined color="primary" sx={{ fontSize: "1.3rem" }} />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  )
}

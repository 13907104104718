import {
  useGetExportTargetsQuery,
  useGetLensesByLensIdLeadsAndLeadIdPredictStatusQuery,
  useGetLensesByLensIdLeadsAndLeadIdQuery,
  useGetLensesByLensIdLeadsAndLeadIdSimilarPastQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
  type Lead1,
  type Target,
} from "@api/leadbayApi"
import { LbFade } from "@components/animations/LbFade/LbFade"
import { type HighlightedFields } from "@components/display/LbDatatableCell/LbDatatableCell"
import { LbUntrashButton } from "@components/inputs/LbUntrashButton/LbUntrashButton"
import { LbWishlistFiltersCard } from "@components/inputs/LbWishlistFiltersCard/LbWishlistFiltersCard"
import { CrunchbaseLogo } from "@components/logos/CrunchbaseLogo"
import { FacebookLogo } from "@components/logos/FacebookLogo"
import { InstagramLogo } from "@components/logos/InstagramLogo"
import { LinkedinLogo } from "@components/logos/LinkedinLogo"
import { TiktokLogo } from "@components/logos/TiktokLogo"
import { XLogo } from "@components/logos/XLogo"
import { ROUTES } from "@constants/index"
import AnimatedNumber from "@crossfox/react-animated-number"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { useExportLeads } from "@hooks/useExportLeads"
import { useRecordUserInteractions } from "@hooks/useRecordUserInteractions"
import {
  ArrowBack,
  ChevronLeft,
  ExpandLess,
  ExpandMore,
  Info,
  Upload,
} from "@mui/icons-material"
import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material"
import { type GridRowParams } from "@mui/x-data-grid"
import {
  selectCommonsState,
  setDrawerData,
  setLeadHistory,
  toggleNavDrawer,
} from "@redux/commonsSlice"
import { getAvailableTargets } from "@utils/getAvailableTargets"
import { upperCaseFirstLetter } from "@utils/globals"
import { formatDate } from "date-fns"
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { LbFavoriteModule } from "./LbFavoriteModule"
import { LbUpdateLeadStatus } from "./LbUpdateLeadStatus"

interface LbLeadData {
  databaseId: string
  keywords: Array<{ label: string; highlighted: boolean; importance: number }>
  location: string
  name: string
  score: number
  website: string
  sector: string
  size: [number, number]
  state: { status: "WON" | "LOST" }
  description: string
  logo: string
  highlighted_fields: HighlightedFields[]
  description_source: string
  social_urls: {
    crunchbase?: string
    facebook?: string
    instagram?: string
    linkedin?: string
    tiktok?: string
    twitter?: string
  }
}

interface LbLeadInfosProps {
  leadData: GridRowParams<Lead1>
}

export const LbLeadInfos = (props: LbLeadInfosProps) => {
  const { leadData } = props

  if (!leadData) return null

  const {
    databaseId,
    social_urls: socialUrls,
    score: rowScore,
  } = leadData as unknown as LbLeadData

  const dispatch = useAppDispatch()

  const windowLocation = useLocation()

  const [localDatabaseId, setLocalDatabaseId] = useState(databaseId)

  const {
    paginationModel,
    pageSize,
    currentFilter,
    sortModel,
    lastNavDrawerPartial,
    navDrawerPartial,
    leadHistory,
    currentLensId,
    wishlistViewMode,
  } = useAppSelector(selectCommonsState)

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const { data: leadIdPredictStatus, isFetching: leadIdPredictStatusFetching } =
    useGetLensesByLensIdLeadsAndLeadIdPredictStatusQuery(
      {
        lensId: currentLensId,
        leadId: localDatabaseId,
      },
      {
        skip: !currentLensId,
      },
    )

  const { refetch: wishlistRefetch } = useGetLensesByLensIdLeadsWishlistQuery({
    lensId: currentLensId,
    count: pageSize,
    page: paginationModel.page,
    q: currentFilter,
    order: sortModel,
  })

  const {
    data: leadByIdData,
    isFetching: leadsByLeadIdFetching,
    refetch,
  } = useGetLensesByLensIdLeadsAndLeadIdQuery(
    {
      lensId: currentLensId,
      leadId: localDatabaseId,
    },
    {
      skip: !currentLensId,
    },
  )

  useEffect(() => {
    if (
      wishlistViewMode !== "timeline" &&
      leadByIdData?.score &&
      rowScore &&
      leadByIdData?.score !== rowScore
    ) {
      wishlistRefetch()
    }
  }, [leadByIdData])

  const {
    score,
    highlighted_fields: highlightedFields,
    website,
    location,
    registry_ids,
    size,
    state,
    sector,
    keywords,
    logo,
    name,
    description,
    description_source: descriptionSource,
    origin,
  } = leadByIdData ?? {}

  const {
    data: leadIdSimilarPast,
    isFetching: leadsByLeadIdSimilarPastIsFetching,
  } = useGetLensesByLensIdLeadsAndLeadIdSimilarPastQuery(
    {
      lensId: currentLensId,
      leadId: localDatabaseId,
    },
    {
      skip: !currentLensId,
    },
  )

  const getHighlightedStyles = (type: HighlightedFields) => {
    const isHighlighted = highlightedFields?.some((field) => field === type)

    return {
      fontFamily: isHighlighted ? "BasementGrotesque" : "Hanken Grotesk",
      fontSize: isHighlighted ? "0.8rem" : "0.9rem",
    }
  }

  const [localesSore, setLocalScore] = useState(0)

  useEffect(() => {
    setLocalDatabaseId(databaseId)

    if (leadHistory.length === 0) {
      dispatch(
        setLeadHistory([
          {
            id: databaseId,
            score,
            highlighted_fields: highlightedFields,
            website,
            location,
            size,
            state,
            sector,
            keywords,
            logo,
            name,
            description,
            description_source: descriptionSource,
            origin,
          } as unknown as Lead1,
          ...leadHistory,
        ]),
      )
    }
  }, [databaseId])

  useLayoutEffect(() => {
    if (!score) return

    setLocalScore(score)
  }, [score])

  const items = useMemo(() => {
    const data = []

    if (registry_ids?.SIRENE) {
      data.push({
        type: "SIRENE",
        icon: "🏢",
        value: registry_ids.SIRENE,
      })
    }

    if (website) {
      data.push({
        type: "WEBSITE",
        icon: "🌐",
        value: website,
      })
    }

    if (location?.full) {
      data.push({
        type: "LOCATION",
        icon: "📍",
        value: location.full,
      })
    }

    if (
      (size?.min !== undefined && size?.min !== 0) ||
      (size?.max !== undefined && size?.max !== 0)
    ) {
      let value = ""

      if (size?.min !== undefined && size?.max !== undefined) {
        value = `${size.min} - ${size?.max} employees`
      } else if (size?.min !== undefined) {
        value = `At least ${size.min} employees`
      } else if (size?.max !== undefined) {
        value = `Up to ${size?.max} employees`
      }

      data.push({
        type: "SIZE",
        icon: "👥",
        value: value,
      })
    }

    if (state?.status) {
      data.push({
        type: "STATUS",
        icon: "📈",
        value: state?.status,
      })
    }

    return data
  }, [location, sector, size, state])

  const formatUrl = (url: string): string =>
    url.startsWith("http://") ||
    url.startsWith("https://") ||
    url.startsWith("www.")
      ? url
      : `//${url}`

  const handleClose = () => {
    if (!navDrawerPartial) return

    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: lastNavDrawerPartial,
      }),
    )
  }

  const handleLeadNavigate = (similarLead: Lead1) => {
    try {
      const id = similarLead.id

      dispatch(setLeadHistory([...leadHistory, similarLead]))

      const size = similarLead.size?.min
        ? [similarLead?.size?.min, similarLead?.size?.max]
        : similarLead?.size

      dispatch(
        setDrawerData({
          id,
          row: {
            databaseId: id,
            score: similarLead?.score,
            highlighted_fields: similarLead?.highlighted_fields,
            website: similarLead?.website,
            location: location?.full,
            size: size ?? null,
            state: similarLead?.state,
            sector: similarLead?.sector,
            keywords: similarLead?.keywords?.map((kw) => ({
              label: kw?.keyword,
              highlighted: kw?.highlighted,
            })),
            logo: similarLead?.logo,
            name: similarLead?.name,
            description: similarLead?.description,
            description_source: similarLead?.description_source,
          },
          columns: [],
        }),
      )
    } catch (error) {
      console.error(`Error navigating to similar lead: ${error}`)
    }
  }

  const handleNavigateBack = () => {
    try {
      const lastLeadIndex =
        leadHistory?.length > 1 ? leadHistory?.length - 2 : 0
      const lastLead = leadHistory?.[lastLeadIndex]

      if (!lastLead) return

      const size = lastLead?.size?.min
        ? [lastLead.size.min, lastLead.size?.max]
        : lastLead.size

      dispatch(
        setDrawerData({
          id: lastLead?.id,
          row: {
            databaseId: lastLead?.id,
            score: lastLead?.score,
            highlighted_fields: lastLead?.highlighted_fields,
            website: lastLead?.website,
            location: location?.full,
            size: size ?? null,
            state: lastLead?.state,
            sector: lastLead?.sector,
            keywords: lastLead?.keywords?.map((kw) => ({
              label: kw?.keyword,
              highlighted: kw?.highlighted,
            })),
            logo: lastLead?.logo,
            name: lastLead?.name,
            description: lastLead?.description,
            description_source: lastLead?.description_source,
          },
          columns: [],
        }),
      )

      dispatch(setLeadHistory(leadHistory.slice(0, leadHistory.length - 1)))
    } catch (error) {
      console.error(`Error navigating back: ${error}`)
    }
  }

  const [showAllKeywords, setShowAllKeywords] = useState(false)

  const handleToggleShowAllKeywords = () => {
    setShowAllKeywords(!showAllKeywords)
  }

  const computedKeywords = keywords
    ?.map((keyword) => ({
      label: keyword?.keyword,
      highlighted: keyword?.highlighted,
      importance: keyword?.importance ?? 0,
    }))
    .sort((a, b) => b.importance - a.importance) as Array<{
    label: string
    highlighted: boolean
    importance: number
  }>

  const firstKeywords = computedKeywords?.slice(0, 10)

  const isTrashScreen =
    windowLocation.pathname === ROUTES.APP + ROUTES.PRIVATE.TRASH

  const handleWebsiteClickInteraction = async () => {
    if (wishlistViewMode !== "timeline")
      await handleRecordUserInteractions([
        {
          type: "LEAD_WEBSITE_CLICKED",
          lead_id: String(leadData.id),
        },
      ])
  }

  const explanationIconRef = useRef<SVGSVGElement>(null)
  const [showPredictedStatusExplanation, setShowPredictedStatusExplanation] =
    useState(false)

  const handleTogglePredictedStatusExplanation = () => {
    setShowPredictedStatusExplanation(!showPredictedStatusExplanation)
  }

  const handleOpenLocationOnGoogleMaps = (location: string) => {
    const address = encodeURIComponent(location)
    const url = `https://www.google.com/maps/search/?api=1&query=${address}`
    window.open(url, "_blank")
  }

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)

    toast(`${text} copied to clipboard`)
  }

  const hasExplanation =
    leadIdPredictStatus?.explanations !== undefined &&
    leadIdPredictStatus?.explanations?.length > 0

  const profileLoading =
    leadsByLeadIdFetching ||
    leadsByLeadIdSimilarPastIsFetching ||
    leadIdPredictStatusFetching

  if (profileLoading) return null

  return (
    <LbFade>
      <Box
        className="LbLeadInfos"
        component="article"
        sx={{
          px: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 3,
            pb: 3,
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    {logo && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={logo}
                          alt={name}
                          style={{
                            objectFit: "contain",
                            height: "35px",
                            borderRadius: "10px",
                          }}
                        />
                      </Box>
                    )}

                    {name && (
                      <Typography
                        variant="h6"
                        fontFamily="BasementGrotesque"
                        sx={{
                          textTransform: "uppercase",
                          lineHeight: 1.2,
                        }}
                      >
                        {name}
                      </Typography>
                    )}
                  </Box>

                  {origin && (
                    <Typography
                      sx={{ mt: 0.5 }}
                      color="text.secondary"
                      variant="caption"
                    >
                      {origin.type === "CRM" && "Imported from " + origin.type}
                      {origin.type === "EXPORTED" &&
                        upperCaseFirstLetter(
                          origin.type.toLocaleLowerCase() + " from whishlist",
                        )}{" "}
                      on {formatDate(origin.date, "y-m-d 'at' H:mm:ss")}
                    </Typography>
                  )}
                </Box>

                {windowLocation.pathname === ROUTES.APP &&
                  ["monitor", "discover"].includes(wishlistViewMode) && (
                    <Tooltip title="back to filters">
                      <IconButton
                        onClick={handleClose}
                        color="primary"
                        sx={{ mt: "-2px", mr: -1 }}
                        disableFocusRipple
                        disableRipple
                      >
                        <ArrowBack />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>

              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {leadHistory.length > 1 && (
                    <IconButton
                      onClick={handleNavigateBack}
                      color="primary"
                      size="small"
                    >
                      <ChevronLeft />
                    </IconButton>
                  )}
                </Box>

                {leadByIdData && (
                  <LbFavoriteModule lead={leadByIdData} refetchLead={refetch} />
                )}

                <LbLeadInfosExport leadData={leadData} />
              </Box>
            </Box>

            {description && (
              <Typography sx={{ mt: logo ? 0 : 1 }} variant="subtitle2">
                {description}
              </Typography>
            )}

            {descriptionSource && (
              <Typography
                sx={{ mt: 1 }}
                color="text.secondary"
                variant="caption"
              >
                source: {descriptionSource}
              </Typography>
            )}
          </Box>

          {isTrashScreen && <LbUntrashButton leadId={leadData.id as string} />}

          <Box
            sx={{
              width: "100%",
            }}
          >
            {state?.status !== "WON" &&
              state?.status !== "LOST" &&
              localesSore !== 0 && (
                <>
                  <Typography
                    fontWeight={900}
                    variant="subtitle1"
                    fontFamily="Hanken Grotesk"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                  >
                    Score
                  </Typography>

                  <Box
                    sx={{ display: "flex", alignItems: "flex-end", ml: -1.5 }}
                  >
                    <Tooltip title="This score shows how closely your lead matches your ideal customer profile (ICP), based on the leads you've imported and the parameters you've set">
                      <Typography
                        variant="h4"
                        fontFamily="BasementGrotesque"
                        sx={{ mb: 0.5, lineHeight: 0.9 }}
                      >
                        <AnimatedNumber
                          rate={100}
                          value={localesSore}
                          duration={400}
                        />
                      </Typography>
                    </Tooltip>
                  </Box>
                </>
              )}

            {state?.status ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: score ? 3 : 0,
                }}
              >
                <Typography
                  fontWeight={900}
                  variant="subtitle1"
                  fontFamily="Hanken Grotesk"
                  color="text.secondary"
                  marginRight={2}
                  lineHeight={1}
                >
                  Status
                </Typography>

                <Chip
                  label={state?.status}
                  color={
                    state?.status === "WON"
                      ? "info"
                      : state?.status === "LOST"
                        ? "warning"
                        : "default"
                  }
                  sx={{ fontWeight: 700 }}
                />

                <LbUpdateLeadStatus leadId={leadData.id as string} />
              </Box>
            ) : leadIdPredictStatus?.status ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 1,
                  mt: 3,
                }}
              >
                <Badge
                  invisible={!leadIdPredictStatus?.changed}
                  badgeContent="new !"
                  color="primary"
                >
                  <Typography
                    fontWeight={900}
                    variant="subtitle1"
                    fontFamily="Hanken Grotesk"
                    color="text.secondary"
                  >
                    Predicted status
                  </Typography>
                </Badge>

                <Tooltip
                  title={
                    leadIdPredictStatus?.changed
                      ? "Status prediction changed recently"
                      : "Status prediction"
                  }
                >
                  <Chip
                    label={leadIdPredictStatus?.status}
                    color={
                      leadIdPredictStatus?.status === "WON"
                        ? "info"
                        : leadIdPredictStatus?.status === "LOST"
                          ? "warning"
                          : leadIdPredictStatus?.status === "WANTED"
                            ? "default"
                            : "default"
                    }
                    sx={{ fontWeight: 700 }}
                    // We use here `deleteIcon` to display an info icon on the right side of the chip.
                    // In reality, this is not a delete icon, but an info icon.
                    deleteIcon={
                      hasExplanation ? (
                        <Info ref={explanationIconRef} />
                      ) : undefined
                    }
                    onDelete={
                      hasExplanation
                        ? handleTogglePredictedStatusExplanation
                        : undefined
                    }
                  />
                </Tooltip>
              </Box>
            ) : null}

            {!state?.status && (
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Typography
                  fontWeight={900}
                  variant="subtitle1"
                  fontFamily="Hanken Grotesk"
                  color="text.secondary"
                  lineHeight={1}
                >
                  Update status
                </Typography>

                <LbUpdateLeadStatus leadId={leadData.id as string} />
              </Box>
            )}

            {hasExplanation && (
              <Popover
                open={showPredictedStatusExplanation}
                anchorEl={explanationIconRef.current}
                onClose={handleTogglePredictedStatusExplanation}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  gap={1}
                  p={2}
                  width={300}
                >
                  {leadIdPredictStatus.explanations
                    ?.slice(0, 3)
                    .map((explanation) => (
                      <Typography
                        key={explanation}
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontStyle: "italic" }}
                      >
                        {explanation}
                      </Typography>
                    ))}
                </Box>
              </Popover>
            )}
          </Box>

          {sector && (
            <Box>
              <Typography
                fontWeight={900}
                variant="subtitle1"
                fontFamily="Hanken Grotesk"
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                Sector
              </Typography>

              <Chip label={sector} sx={{ fontWeight: 700 }} />
            </Box>
          )}

          {leadIdSimilarPast && leadIdSimilarPast?.length > 0 && (
            <Box>
              <Typography
                fontWeight={900}
                variant="subtitle1"
                fontFamily="Hanken Grotesk"
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                Similar past leads
              </Typography>

              {leadIdSimilarPast?.map((similarLead) => (
                <>
                  <Chip
                    onClick={() => {
                      handleLeadNavigate(similarLead)
                    }}
                    key={similarLead.id}
                    label={similarLead.name}
                    color={
                      similarLead.state?.status === "WON"
                        ? "info"
                        : similarLead.state?.status === "LOST"
                          ? "warning"
                          : similarLead.state?.status === "WANTED"
                            ? "default"
                            : "default"
                    }
                    sx={{ mr: 1, mb: 1, fontWeight: 700 }}
                  />
                </>
              ))}
            </Box>
          )}

          <Box>
            <Typography
              fontWeight={900}
              variant="subtitle1"
              fontFamily="Hanken Grotesk"
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              Info
            </Typography>

            <Box>
              {items.map(({ value, icon, type }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                    "&:last-child": {
                      marginBottom: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {icon}
                  </Box>

                  {type === "WEBSITE" ? (
                    <a
                      onClick={handleWebsiteClickInteraction}
                      href={formatUrl(value)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{
                          fontSize: "1rem",
                        }}
                      >
                        {value}
                      </Typography>
                    </a>
                  ) : type === "LOCATION" ? (
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      onClick={() => handleOpenLocationOnGoogleMaps(value)}
                      sx={{
                        ...getHighlightedStyles(type as HighlightedFields),
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {value}
                    </Typography>
                  ) : (
                    <Tooltip title={`Copy to clipboard`}>
                      <Typography
                        onClick={() => handleCopyToClipboard(value)}
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{
                          ...getHighlightedStyles(type as HighlightedFields),
                          cursor: "pointer",
                        }}
                      >
                        {value}
                      </Typography>
                    </Tooltip>
                  )}
                </Box>
              ))}

              {socialUrls &&
                Object.entries(socialUrls).map(([socialName, socialUrl]) => (
                  <Box
                    key={socialName}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      my: 2,
                      "&:last-child": {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {socialName === "crunchbase" && (
                        <CrunchbaseLogo fill="#0288D1" />
                      )}
                      {socialName === "facebook" && (
                        <FacebookLogo fill="#0866FF" />
                      )}
                      {socialName === "instagram" && (
                        <InstagramLogo fill="#E4405F" />
                      )}
                      {socialName === "linkedin" && (
                        <LinkedinLogo fill="#0A66C2" />
                      )}
                      {socialName === "tiktok" && <TiktokLogo />}
                      {socialName === "twitter" && <XLogo fill="#000000" />}
                    </Box>

                    <a
                      href={socialUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{
                          ...getHighlightedStyles(
                            socialName as HighlightedFields,
                          ),
                          textDecoration: "underline",
                        }}
                      >
                        {socialName === "crunchbase"
                          ? "Crunchbase"
                          : socialName === "facebook"
                            ? "Facebook"
                            : socialName === "instagram"
                              ? "Instagram"
                              : socialName === "linkedin"
                                ? "LinkedIn"
                                : socialName === "tiktok"
                                  ? "TikTok"
                                  : socialName === "twitter"
                                    ? "X (formerly Twitter)"
                                    : ""}
                      </Typography>
                    </a>
                  </Box>
                ))}
            </Box>

            {computedKeywords?.length > 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontWeight={900}
                    variant="subtitle1"
                    fontFamily="Hanken Grotesk"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 3 }}
                  >
                    Keywords
                  </Typography>

                  <Box
                    onClick={handleToggleShowAllKeywords}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {showAllKeywords ? "Show less" : "Show all"}
                    </Typography>

                    <IconButton size="small">
                      {showAllKeywords ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Box>
                </Box>

                <LbWishlistFiltersCard
                  readonly
                  variant="outlined"
                  py={0}
                  filterItem={{
                    name: "Size",
                    score: 0,
                    items: showAllKeywords ? computedKeywords : firstKeywords,
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </LbFade>
  )
}

interface LbLeadInfosExportProps {
  leadData: GridRowParams<Lead1>
}

const LbLeadInfosExport = (props: LbLeadInfosExportProps) => {
  const { data: targetsList } = useGetExportTargetsQuery()

  const targets = getAvailableTargets(targetsList)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box ml={0.5}>
      <IconButton onClick={handleOpenExportMenu}>
        <Upload color="primary" sx={{ fontSize: "1.5rem" }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {targets?.targets?.map((target) => (
          <LbLeadInfosExportTarget
            key={target.id}
            target={target}
            leadData={props.leadData}
            onClick={() => setAnchorEl(null)}
          />
        ))}
      </Menu>
    </Box>
  )
}

interface LbLeadInfosExportTargetProps {
  target: Target
  leadData: GridRowParams<Lead1>
  onClick: () => void
}

const LbLeadInfosExportTarget = (props: LbLeadInfosExportTargetProps) => {
  const { target, leadData } = props

  const { wishlistViewMode } = useAppSelector(selectCommonsState)

  const targetName = useMemo(() => {
    if (target.id === "f") {
      return "CSV"
    }

    return target.connector?.name
  }, [target, target.id, target.connector?.name])

  const { handleExportLeads } = useExportLeads({
    onSuccess: useCallback(() => {
      if (target.id === "f") {
        toast.success(
          "The leads have been successfully downloaded and moved to the monitor view.",
        )
      } else {
        toast.success("Exporting leads to selected Zapier connector")
      }
    }, [target.id, toast]),
    onError: useCallback(
      (error: Error) => {
        toast.error(error.message ?? `Failed to export to ${targetName}`)
      },
      [toast, targetName],
    ),
    onFinished: props.onClick,
  })

  return (
    <Button
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
      onClick={async () =>
        await handleExportLeads({
          wishlistViewMode,
          selectedTarget: target.id,
          selectedLeads: [leadData.id as string],
        })
      }
    >
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{
          fontFamily: "Hanken Grotesk",
          fontSize: "1rem",
        }}
      >
        {targetName}
      </Typography>
    </Button>
  )
}
